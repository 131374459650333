import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import "./Dashboard.css";
import {
  message,
  Row,
  Col,
  Table,
  Card,
  Layout,
  Button,
  Space,
  Select,
  DatePicker,
} from "antd";
import axios from "axios";
import { url } from "../../constant/url";
import { Bar, Doughnut } from "react-chartjs-2";
import moment from "moment";
import { Fragment } from "react/cjs/react.production.min";
import { ExportOutlined } from "@ant-design/icons";
import fileDownload from "js-file-download";
import AntNumberFormat from "../../components/AntNumberFormat";
// import "chartjs-plugin-datalabels";

import _, { filter } from "underscore";

const { Content } = Layout;
const { Option } = Select;

function CobaDash({ history }) {
  const [data, setData] = useState({
    plan_id: "",
    plan_name: "",
    main_process_id: "",
    main_process_name: "",
    line_process_id: "",
    line_process_name: "",
    part_id: "",
    part_number: "",
    user_id: "",
    user_full_name: "",
    start_date: moment().startOf("date"),
  });

  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [itemData, setItemData] = useState({});
  const [Departments, setDepartments] = useState([]);
  const [Mains, setMains] = useState([]);
  const [Operators, setFOperator] = useState([]);
  const [Lines, setLines] = useState([]);
  const [parts, setParts] = useState([]);
  const [planperjam, setPlanPerJam] = useState([]);
  const [shiftTimes, setShiftTimes] = useState([]);
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);
  const [sublevels, setSublevels] = useState([]);
  const [errors, setErrors] = useState(null);
  const [actplan, setActplan] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [cycleTimes, setCycleTimes] = useState([]);
  const [columns, setColumns] = useState([
    {
      title: "Time",
      dataIndex: "resource",
      key: "resource",
      colSpan: 2,
    },
    {
      title: "Time",
      dataIndex: "unit",
      key: "unit",
      colSpan: 0,
    },
  ]);

  const [columnsPlan, setColumnsPlan] = useState([
    {
      title: "Time",
      dataIndex: "resource",
      key: "resource",
      colSpan: 2,
    },
    {
      title: "Time",
      dataIndex: "unit",
      key: "unit",
      colSpan: 0,
    },
  ]);

  useEffect(() => {
    getShifts();
  }, []);

  useEffect(() => {
    makeInitialData();
  }, []);

  const getShifts = () => {
    axios
      .get(`${url}/shift`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setShifts(res.data.data);
        // getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  useEffect(() => {
    generateTime();
  }, [shifts]);

  const generateTime = () => {
    const times = [];
    const dataShiftTimes = [];

    shifts.map((x) => {
      const timeIn = moment(x.time_in, "HH:mm").format("H");
      const timeOut = moment(x.time_out, "HH:mm").format("H");

      let periodsInDay;

      if (parseInt(timeIn) > parseInt(timeOut)) {
        periodsInDay = moment
          .duration(
            moment(x.time_out, "HH:mm")
              .add(1, "day")
              .diff(moment(x.time_in, "HH:mm"))
          )
          .as("hours");
      } else {
        periodsInDay = moment
          .duration(
            moment(x.time_out, "HH:mm").diff(moment(x.time_in, "HH:mm"))
          )
          .as("hours");
      }

      const timeLabels = [];
      const dataShiftTimesLabels = [];

      for (let i = 0; i <= periodsInDay; i++) {
        dataShiftTimesLabels.push({
          time: moment(x.time_in, "HH:mm").add(i, "hour").format("HH:mm"),
        });
        timeLabels.push({
          title: moment(x.time_in, "HH:mm").add(i, "hour").format("HH:mm"),
          dataIndex: moment(x.time_in, "HH:mm").add(i, "hour").format("HH:mm"),
          align: "center",
          key: moment(x.time_in, "HH:mm").add(i, "hour").format("HH:mm"),

          render: (data_table, unit) => {
            if (unit.unit == "%") {
              return <div>{Math.floor(data_table) + " %"}</div>;
            } else {
              return <div>{Math.floor(data_table)}</div>;
              // return console.log("tes", unit);
            }
          },
        });
      }

      times.push(...timeLabels);
      dataShiftTimes.push(...dataShiftTimesLabels);
    });

    // console.log([...columns, ...times], "columns times");
    setShiftTimes(dataShiftTimes);
    setColumns([...columns, ...times]);
  };

  useEffect(() => {
    generateTimePlanHeijunka();
  }, [shifts]);

  const generateTimePlanHeijunka = () => {
    const times = [];
    const dataShiftTimes = [];

    shifts.map((x) => {
      const timeIn = moment(x.time_in, "HH:mm").format("H");
      const timeOut = moment(x.time_out, "HH:mm").format("H");

      let periodsInDay;

      if (parseInt(timeIn) > parseInt(timeOut)) {
        periodsInDay = moment
          .duration(
            moment(x.time_out, "HH:mm")
              .add(1, "day")
              .diff(moment(x.time_in, "HH:mm"))
          )
          .as("hours");
      } else {
        periodsInDay = moment
          .duration(
            moment(x.time_out, "HH:mm").diff(moment(x.time_in, "HH:mm"))
          )
          .as("hours");
      }

      const timeLabels = [];
      const dataShiftTimesLabels = [];

      for (let i = 0; i <= periodsInDay; i++) {
        dataShiftTimesLabels.push({
          time: moment(x.time_in, "HH:mm").add(i, "hour").format("HH:mm"),
        });
        timeLabels.push({
          title: moment(x.time_in, "HH:mm").add(i, "hour").format("HH:mm"),
          dataIndex: moment(x.time_in, "HH:mm").add(i, "hour").format("HH:mm"),
          align: "center",
          key: moment(x.time_in, "HH:mm").add(i, "hour").format("HH:mm"),

          render: (data_cycle_time, unit) => {
            if (unit.unit == "%") {
              return <div>{Math.floor(data_cycle_time)}</div>;
            } else {
              return <div>{data_cycle_time}</div>;
              // return console.log("tes", unit);
            }
          },
        });
      }

      times.push(...timeLabels);
      dataShiftTimes.push(...dataShiftTimesLabels);
    });

    // console.log([...columns, ...times], "columns times");
    setShiftTimes(dataShiftTimes);
    setColumnsPlan([...columnsPlan, ...times]);
  };

  const makeInitialData = () => {
    // let [labels, values, sublevels] = initialData.map(_.values).unzip().value();
    setLabels(labels);
    setValues(values);
    setSublevels(sublevels);
  };

  const mapSubData = (index) => {
    let [a, b, c] = _(sublevels[index]).map(_.values).unzip().value();
    setLabels(a);
    setValues(b);
    setSublevels(c);
  };

  // console.log("columns", columns);

  const getDepartment = async (keyword) => {
    await axios
      .get(`${url}/mapping-process/list-plan`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setDepartments(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getMain = async (keyword) => {
    await axios
      .get(`${url}/mapping-process/list-main`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
          plan_id: data.plan_id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setMains(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getLine = async (keyword) => {
    await axios
      .get(`${url}/mapping-process/list-line-dashboard`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
          plan_id: data.plan_id,
          main_process_id: data.main_process_id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLines(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getPart = async (keyword) => {
    await axios
      .get(`${url}/part/list-map`, {
        params: {
          plan_id: data.plan_id,
          main_process_id: data.main_process_id,
          line_process_id: data.line_process_id,
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setParts(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getOperator = async (keyword) => {
    await axios
      .get(`${url}/user/list-user-role`, {
        params: {
          keyword,

          role_name: "Operator",
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setFOperator(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getData = () => {
    setLoading(true);

    let heijunka_acc = [];
    let actual_acc = [];
    let actual = [];
    let heijunka = [];
    let efficiency = [];
    let itemName = [];

    axios
      .get(`${url}/dashboard-new`, {
        params: {
          keyword,
          columns: [
            "date",
            "main_process_id",
            "main_process_name",
            "line_process_id",
            "line_process_name",
            "part_id",
            "part_name",
            "plan_id",
            "plan_name",
            "user_id",
            "user_full_name",
            "actual",
            "plan",
            "out",
          ],
          perpage: perpage,
          page,
          order: sort.order,
          plan_id: data.plan_id,
          main_process_id: data.main_process_id,
          line_process_id: data.line_process_id,
          part_id: data.part_id,
          user_id: data.user_id,
          start_date: moment(data.start_date).format("DD-MM-YYYY"),
        },

        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const data_bar = res.data.data_bar;
        const pluckedShiftTime = _.pluck(shiftTimes, "time");
        const filteredDataBar = data_bar.filter((bar) => {
          return pluckedShiftTime.includes(bar.label);
        });
        setPlanPerJam(res.data.data_table);
        setCycleTimes(res.data.data_cycle_time);
        setActplan(res.data);
        for (const dataObj of filteredDataBar) {
          itemName.push(dataObj.label);
          heijunka.push(parseInt(dataObj.heijunka));
          heijunka_acc.push(parseInt(dataObj.heijunka_acc));
          actual.push(parseInt(dataObj.actual));
          actual_acc.push(parseInt(dataObj.actual_acc));
          efficiency.push(parseInt(dataObj.efficiency));
        }

        setItemData({
          labels: itemName,
          datasets: [
            {
              label: "Heijunka Acc",
              data: heijunka_acc,
              type: "line",
              backgroundColor: "#E67E00",
              borderColor: "#E67E00",
            },
            {
              label: "Actual Acc",
              data: actual_acc,
              type: "line",
              backgroundColor: "#0000CD",
              borderColor: "#0000CD",
            },
            {
              label: "Efficiency",
              data: efficiency,
              type: "line",
              backgroundColor: "red",
              borderColor: "red",
            },
            {
              label: "Heijunka",
              data: heijunka,
              backgroundColor: "#FF8C00	",
              borderColor: "#FF8C00",
              yAxisID: "Kiri",
            },
            {
              label: "Actual",
              data: actual,
              backgroundColor: "#0000CD",
              borderColor: "#0000CD",
              yAxisID: "Kiri",
            },
          ],
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
          }
          message.error(error.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (shiftTimes.length > 0) {
      getData();
    }
  }, [data, shiftTimes]);

  const options = {
    plugins: {
      datalabels: {
        display: true,
        align: "top",
        color: "black",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        gridLines: {
          display: true,
        },
      },
      y: {
        display: true,
        position: "right",
        scaleLabel: {
          display: true,
          beginAtZero: true,
        },
      },
      y1: {
        display: false,
        scaleLabel: {
          display: true,
          beginAtZero: true,
        },
      },
      // xAxes: [
      //   {
      //     gridLines: {
      //       display: false,
      //     },
      //   },
      // ],
      // yAxes: [
      //   {
      //     display: true,
      //     position: "left",
      //     scaleLabel: {
      //       display: true,
      //       beginAtZero: true,
      //     },
      //     id: "YKiri",
      //   },
      //   {
      //     display: true,
      //     position: "right",
      //     scaleLabel: {
      //       display: true,
      //       beginAtZero: true,
      //     },
      //     id: "YKanan",
      //   },
      // ],
    },
  };

  const handleSelect = (name, value) => {
    setErrors(null);
    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
        [`${name}_number`]: value.label,
        [`${name}_full_name`]: value.label,
        [name]: value,
      });
    } else {
      setData({
        ...data,
        main_processes: [],
        line_processes: [],
        [`${name}_id`]: null,
        [`${name}_name`]: null,
        [`${name}_number`]: null,
        [`${name}_full_name`]: value.label,
        [name]: value,
      });
    }
  };

  const handleChangeViewDepartment = (name, value) => {
    setErrors(null);

    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  const handleChangeViewMain = (name, value) => {
    setErrors(null);

    if (value) {
      setData({
        ...data,
        main_processes: [],
        line_processes: [],
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
        [name]: value,
      });
    } else {
      setData({
        ...data,
        main_processes: [],
        line_processes: [],
        [`${name}_id`]: null,
        [`${name}_name`]: null,
        [name]: value,
      });
    }
  };

  const handleChangeViewLine = (name, value) => {
    setErrors(null);

    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setData({
        ...data,
        main_processes: [],
        line_processes: [],
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  const handleChangeViewPart = (name, value) => {
    setErrors(null);

    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_number`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_number`]: null,
      });
    }
  };

  const handleChangeViewOperator = (name, value) => {
    setErrors(null);

    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_full_name`]: value.label,
        [name]: value,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_full_name`]: null,
        [name]: value,
      });
    }
  };

  const handleChangeDate = (value) => {
    if (value) {
      setData({
        ...data,
        start_date: value,
      });
    } else {
      setData({
        ...data,
        start_date: undefined,
      });
    }
  };

  const downloadTemplate = () => {
    axios
      .get(`${url}/dashboard/download`, {
        params: {
          keyword,
          columns: ["resource", "unit"],
          perpage: perpage,
          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, "export_dashboard.xlsx");
      });
  };

  return (
    <Fragment>
      <Row className="header-body">
        <Col xs={24}>
          <Card
            style={{ textAlign: "center", fontSize: "40pt" }}
            type="inner"
            className="title-dashboard"
            title="Daily Production Control (Plan VS Actual) "
            bodyStyle={{ padding: "0" }}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row gutter={[16, 20]}>
          <Col xs={24} md={6} lg={4} className="sidebar-body">
            <Card>
              <Space direction="vertical" size={12}>
                Date
                <DatePicker
                  style={{ width: 150 }}
                  onChange={handleChangeDate}
                  picker="date"
                  value={data.start_date}
                  format="DD-MMM-YYYY"
                />
              </Space>
            </Card>

            <Card>
              <Space direction="vertical" size={12}>
                Department
                <Select
                  allowClear="true"
                  style={{ width: 150 }}
                  className="width"
                  labelInValue
                  onChange={(value) =>
                    handleChangeViewDepartment("plan", value)
                  }
                  onFocus={() => getDepartment("")}
                  showSearch
                  onSearch={(value) => getDepartment(value)}
                  filterOption={false}
                >
                  {Departments &&
                    Departments.map((plan) => {
                      return (
                        <Option value={plan._id} key={plan._ide}>
                          {plan.name}
                        </Option>
                      );
                    })}
                </Select>
              </Space>
            </Card>

            <Card style={{ marginTop: 10 }}>
              <Space direction="vertical" size={12}>
                Main Process
                <Select
                  allowClear="true"
                  style={{ width: 150 }}
                  className="width"
                  labelInValue
                  onChange={(value) =>
                    handleChangeViewMain("main_process", value)
                  }
                  onFocus={() => getMain("")}
                  showSearch
                  onSearch={(value) => getMain(value)}
                  filterOption={false}
                >
                  {Mains &&
                    Mains.map((main) => {
                      return (
                        <Option
                          value={main.main_process_id}
                          key={main.main_process_id}
                        >
                          {main.main_process_name}
                        </Option>
                      );
                    })}
                </Select>
              </Space>
            </Card>

            <Card style={{ marginTop: 10 }}>
              <Space direction="vertical" size={12}>
                Line Process
                <Select
                  allowClear="true"
                  style={{ width: 150 }}
                  className="width"
                  labelInValue
                  onChange={(value) =>
                    handleChangeViewLine("line_process", value)
                  }
                  onFocus={() => getLine("")}
                  showSearch
                  onSearch={(value) => getLine(value)}
                  filterOption={false}
                >
                  {Lines &&
                    Lines.map((line) => {
                      return (
                        <Option
                          value={line.line_process_id}
                          key={line.line_process_id}
                        >
                          {line.line_process_name}
                        </Option>
                      );
                    })}
                </Select>
              </Space>
            </Card>

            <Card style={{ marginTop: 10 }}>
              <Space direction="vertical" size={12}>
                Part Number
                <Select
                  allowClear="true"
                  style={{ width: 150 }}
                  className="width"
                  labelInValue
                  onChange={(value) => handleChangeViewPart("part", value)}
                  onFocus={() => getPart("")}
                  showSearch
                  onSearch={(value) => getPart(value)}
                  filterOption={false}
                >
                  {parts &&
                    parts.map((part) => {
                      return (
                        <Option value={part._id} key={part._ide}>
                          {part.number}
                        </Option>
                      );
                    })}
                </Select>
              </Space>
            </Card>

            <Card style={{ marginTop: 10 }}>
              <Space direction="vertical" size={12}>
                Operator
                <Select
                  allowClear="true"
                  style={{ width: 150 }}
                  className="width"
                  labelInValue
                  onChange={(value) => handleChangeViewOperator("user", value)}
                  onFocus={() => getOperator("")}
                  showSearch
                  onSearch={(value) => getOperator(value)}
                  filterOption={false}
                >
                  {Operators &&
                    Operators.map((user) => {
                      return (
                        <Option value={user._id} key={user._id}>
                          {user.full_name}
                        </Option>
                      );
                    })}
                </Select>
              </Space>
            </Card>
          </Col>
          <Col xs={24} md={18} lg={20} className="main-body">
            <Card style={{ marginBottom: 10 }}>
              <Row gutter={[16, 20]}>
                <Col xs={12} md={18} lg={20}>
                  <Bar data={itemData} options={options} />
                </Col>

                <Col xs={24} md={6} lg={4}>
                  <div className="contentTotal">
                    <div className="card">
                      <div className="card-header card-header-color-green">
                        <h4
                          className="card-title text-white"
                          style={{
                            height: 35,
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          PLAN
                        </h4>
                      </div>

                      <div
                        className="card-body"
                        style={{
                          height: 70,
                          borderBlockColor: "black",
                          textAlign: "center",
                          fontSize: "15pt",
                          marginTop: -5,
                          border: "1px solid black",
                          marginBottom: 10,
                        }}
                      >
                        <div style={{ marginTop: 13 }}>
                          {actplan ? actplan.plan : 0}
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header card-header-color-orange">
                        <h4
                          className="card-title text-white"
                          style={{
                            height: 35,
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          ACTUAL
                        </h4>
                      </div>

                      <div
                        className="card-body"
                        style={{
                          height: 70,
                          borderBlockColor: "black",
                          textAlign: "center",
                          fontSize: "15pt",
                          marginTop: -5,
                          border: "1px solid black",
                          marginBottom: 10,
                        }}
                      >
                        <div style={{ marginTop: 13 }}>
                          {actplan ? actplan.actual : 0}
                        </div>{" "}
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header card-header-color-yellow">
                        <h4
                          className="card-title text-white"
                          style={{
                            height: 35,
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          PLAN ACHIEVMENT
                        </h4>
                      </div>

                      <div
                        className="card-body"
                        style={{
                          height: 70,
                          borderBlockColor: "black",
                          textAlign: "center",
                          fontSize: "15pt",
                          marginTop: -5,
                          border: "1px solid black",
                          marginBottom: 10,
                        }}
                      >
                        <div style={{ marginTop: 13 }}>
                          {Math.floor(actplan.achievment)} %
                        </div>
                        <div style={{ marginTop: "auto", fontSize: 12 }}>
                          (Actual / Plan) * 100
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header card-header-color-red">
                        <h4
                          className="card-title text-white"
                          style={{
                            height: 35,
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          LOST TIME
                        </h4>
                      </div>

                      <div
                        className="card-body"
                        style={{
                          height: 70,
                          borderBlockColor: "black",
                          textAlign: "center",
                          fontSize: "15pt",
                          marginTop: -5,
                          border: "1px solid black",
                          marginBottom: 10,
                        }}
                      >
                        <div style={{ marginTop: 13 }}>
                          {actplan.hours}:{actplan.minutes}
                        </div>
                        <div style={{ marginTop: "auto", fontSize: 12 }}>
                          Total Breakdown
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header card-header-color-red">
                        <h4
                          className="card-title text-white"
                          style={{
                            height: 35,
                            textAlign: "center",
                            border: "1px solid black",
                          }}
                        >
                          PRODUCTIVITY
                        </h4>
                      </div>

                      <div
                        className="card-body"
                        style={{
                          height: 70,
                          borderBlockColor: "black",
                          textAlign: "center",
                          fontSize: "15pt",
                          marginTop: -5,
                          border: "1px solid black",
                          marginBottom: 10,
                        }}
                      >
                        <div style={{ marginTop: 13 }}>
                          {parseFloat(actplan.productivity).toFixed(2)}
                        </div>
                        <div style={{ marginTop: "auto", fontSize: 12 }}>
                          Actual / (WH+OT)-Lost Time
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>

            <Card
              style={{
                marginBottom: 10,
              }}
            >
              <Button
                onClick={downloadTemplate}
                type="primary"
                style={{
                  display: "flex",
                  marginBottom: 15,
                  backgroundColor: "#1b2086",
                  borderColor: "#1b2086",
                  marginLeft: "auto",
                }}
              >
                <ExportOutlined />
                Export
              </Button>

              <Table
                style={{ marginTop: 20 }}
                size="small"
                scroll={{ x: 1000 }}
                maxWidth="190"
                dataSource={planperjam}
                columns={columns}
                rowKey={(data) => data.no}
                bordered
                scroll={{ x: "auto" }}
              />
            </Card>
            <Card>
              <Table
                style={{ marginTop: 20 }}
                size="small"
                scroll={{ x: 1000 }}
                maxWidth="190"
                dataSource={cycleTimes}
                columns={columnsPlan}
                rowKey={(data) => data.no}
                bordered
                scroll={{ x: "auto" }}
                pagination={false}
              />
            </Card>

            <Row>
              <Col span={12}>
                <Card>
                  <Doughnut
                    data={{
                      labels: ["red", "blue", "yellow"],

                      datasets: [
                        {
                          label: "# of votes",
                          data: [19, 3, 5],
                          backgroundColor: ["#FF8C00", "#0000CD", "#708090"],
                        },
                      ],
                    }}
                    options={{
                      legend: true,
                      responsive: true,
                      maintainAspectRatio: false,
                      scaleShowVerticalLines: false,
                      showInLegend: true,
                      legend: {
                        display: true,
                        position: "left",
                        maxWidth: 100,
                      },

                      animation: {
                        animateScale: true,
                      },
                    }}
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                  <Doughnut
                    data={{
                      labels: ["red", "blue", "yellow"],

                      datasets: [
                        {
                          label: "# of votes",
                          data: [19, 3, 5],
                          backgroundColor: ["#4682B4", "#0000CD", "#708090"],
                        },
                      ],
                    }}
                    options={{
                      legend: true,
                      responsive: true,
                      maintainAspectRatio: false,
                      scaleShowVerticalLines: false,
                    }}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default CobaDash;
