import {
  Button,
  Col,
  Input,
  message,
  Row,
  Switch,
  Menu,
  Layout,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./RoleForm.css";
import axios from "axios";
import { url } from "../../constant/url";
import _ from "underscore";
import { getRoles } from "@testing-library/dom";

const { Title } = Typography;
const { Content } = Layout;
const { TextArea } = Input;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function RoleForm({ history, match }) {
  const [data, setData] = useState({
    name: "",
    description: "",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [rolePermission, setRolePermission] = useState([]);

  useEffect(() => {
    if (match.params.id) {
      getData();
      getRole();
      getPermission();
    }
  }, []);

  const getRole = () => {
    axios
      .get(`${url}/role/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setData({
          ...data,
          name: res.data.data.name,
          description: res.data.data.description,
        });

        setRolePermission(res.data.data.perms);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setErrors(null);
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleChangeSwitch = (name, value) => {
    setRolePermission({
      ...rolePermission,
      [name]: value,
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(
        match.params.id ? `${url}/role/${match.params.id}` : `${url}/role`,
        {
          name: data.name,
          description: data.description,
          permissions: rolePermission,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/role");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/role/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const { name, description, permissions } = res.data.data;
        setData({
          ...data,
          name,
          description,
          permissions,
        });
        setRolePermission(res.data.data.perms);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      name: "",
      description: "",
    });
  };

  // useEffect(() => {
  //   getPermission();
  // }, []);

  const getPermission = () => {
    setLoading(true);

    axios
      .get(`${url}/permission/list-permission-parent`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setPermissions(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setErrors(err.response.data.errors);
          }
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/role">Role</Link>
        </li>
        <li className="active">{match.params.id ? "Edit Role" : "Add Role"}</li>
      </ul>
      <div className="content">
        <form {...layout} onSubmit={handleSave}>
          <Row gutter={20}>
            <Col md={18} xs={24}>
              <div className="form-group">
                <label className="form-label">
                  Role <span className="error-text">*</span>
                </label>
                <Input
                  value={data.name}
                  className={errors && errors.name ? "is-error" : ""}
                  onChange={(e) => handleChange("name", e.target.value)}
                />
                {errors && errors.name && (
                  <span className="error-text">{errors.name[0]}</span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">Description</label>
                <Input.TextArea
                  rows={6}
                  value={data.description}
                  className={errors && errors.description ? "is-error" : ""}
                  onChange={(e) => handleChange("description", e.target.value)}
                />
                {errors && errors.description && (
                  <span className="error-text">{errors.description[0]}</span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">Permission</label>
                <Row gutter={[10, 10]}>
                  {permissions &&
                    permissions.map((permission) => {
                      return (
                        <Col key={permission._id}>
                          <Space>
                            <Switch
                              checked={
                                rolePermission[permission._id]
                                  ? rolePermission[permission._id]
                                  : false
                              }
                              value={permission._id}
                              onChange={(value) =>
                                handleChangeSwitch(permission._id, value)
                              }
                            />
                            <span>{permission.name}</span>
                          </Space>
                        </Col>
                      );
                    })}
                </Row>
              </div>

              <div className="form-group">
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={loading}
                  style={{
                    backgroundColor: "#1b2086",
                    borderColor: "#1b2086",
                  }}
                >
                  Submit
                </Button>

                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={handleReset}
                  style={{
                    backgroundColor: "#1b2086",
                    borderColor: "#1b2086",
                    marginLeft: 10,
                  }}
                >
                  Reset
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default RoleForm;
