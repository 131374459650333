let url;
let socketUrl;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // url = "http://api.sp.ns1.sanoh.co.id/api";
  // url = "http://192.168.18.19:8000/api";
  url = "https://192.168.1.7:8000/api";
  //url = "https://api.sanoh.kelola.biz/api";
  // socketUrl = "http://192.168.100.114:3002";
  socketUrl = "http://10.1.10.39:3002";
} else {
  url = "https://api.pre-task.kelola.biz/api";
  // url = "http://192.168.18.19:8000/api";
  // url = "http://api.sp.ns1.sanoh.co.id/api";
  // url = "http://192.168.100.114:8000/api";
  socketUrl = "https://10.1.10.39:3002";
}

export { url, socketUrl };

// examp
