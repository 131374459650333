import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Table,
  Card,
  Upload,
} from "antd";
import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { url } from "../../constant/url";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ImportOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import fileDownload from "js-file-download";

function Department(history) {
  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: true,
    },
    {
      title: "Department",
      dataIndex: "name",
      key: "name",
      sorter: false,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: false,
    },
    {
      title: "",
      dataIndex: "_id",
      key: "id",
      sorter: false,
      fixed: "right",
      width: 50,
      render: (id) => {
        return (
          <>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Link to={`/department/edit/${id}`}>
                      {/* <i className="mdi mdi-pencil mr-5" /> Edit */}
                      <EditOutlined /> Edit
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <span onClick={() => handleDelete(id)}>
                      {/* <i className="mdi mdi-delete mr-5" /> */}
                      <DeleteOutlined />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <i className="mdi mdi-dots-vertical" />
              </span>
            </Dropdown>
          </>
        );
      },
    },
  ];

  const [total, setTotal] = useState([]);
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [sort, setSort] = useState({
    sort: "code",
    order: "ascend",
  });
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(null);
  const [departments, setDepartment] = useState([]);

  const [data, setData] = useState({
    file: null,
  });

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  useEffect(() => {
    getData();
  }, [keyword, page, perpage, sort]);

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/plan`, {
        params: {
          keyword,
          columns: ["name", "description", "code"],
          perpage: perpage,
          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setDepartment(res.data);
        setTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure ?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    axios
      .delete(`${url}/plan/${id}`, {
        params: {
          keyword,
          columns: ["name", "description", "code"],
          perpage: perpage,
          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPerpage(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const downloadTemplate = () => {
    axios
      .get(`${url}/plan/download`, {
        params: {
          keyword,
          columns: ["name", "description", "code"],
          perpage: perpage,
          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, "export_department.xlsx");
      });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    axios
      .post(`${url}/plan/import`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  return localStorage.getItem("token") ? (
    <div>
      <ul className="breadcumb">
        <li className="active">Department</li>
      </ul>
      <div className="content">
        <Row justify="space-between">
          <Col>
            <Link to="/department/create">
              <Button
                type="primary"
                style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}
              >
                <PlusOutlined />
                Add
              </Button>
            </Link>
            <Modal
              title="Import Department"
              visible={isModalVisible}
              footer={null}
              onCancel={closeModal}
              onCancel={handleCancel}
            >
              <Card className="body-data">
                <div name="control-hooks">
                  <div name="file" label="File">
                    <Fragment>
                      <Upload
                        onRemove={handleRemove}
                        beforeUpload={handleBeforeUpload}
                        fileList={[]}
                      >
                        <Button style={{ marginBottom: 10 }}>
                          Choose File <ExportOutlined />
                        </Button>
                      </Upload>
                      {data.file && (
                        <React.Fragment
                          children={[
                            <Button onClick={handleRemove} type="danger" ghost>
                              <DeleteOutlined />
                            </Button>,
                          ]}
                        >
                          {data.file.name ? data.file.name : data.file}
                        </React.Fragment>
                      )}
                    </Fragment>
                  </div>
                  <div>
                    <Button
                      loading={loading}
                      onClick={onSaveData}
                      type="primary"
                      htmlType="submit"
                      className="mr-button"
                      style={{
                        marginRight: 10,
                        backgroundColor: "#1b2086",
                        borderColor: "#1b2086",
                      }}
                    >
                      Upload
                    </Button>
                    <Button htmlType="reset" onClick={handleRemove}>
                      Reset
                    </Button>
                  </div>
                </div>
              </Card>
            </Modal>
            <Button
              onClick={showModal}
              type="primary"
              style={{
                backgroundColor: "#1b2086",
                borderColor: "#1b2086",
                marginLeft: 10,
              }}
            >
              <ImportOutlined />
              Import
            </Button>
            <Button
              onClick={downloadTemplate}
              type="primary"
              style={{
                backgroundColor: "#1b2086",
                borderColor: "#1b2086",
                marginLeft: 10,
              }}
            >
              <ExportOutlined />
              Export
            </Button>
          </Col>
          <Col>
            <Input.Search
              allowClear="true"
              onSearch={(value) => setKeyword(value)}
              placeholder="Search..."
            />
          </Col>
        </Row>
        <Row className="mt-40">
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={departments.data}
              pagination={{ total, showSizeChanger: true }}
              loading={loading}
              rowKey={(data) => data._id}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </div>
    </div>
  ) : (
    <Redirect to="/login" />
  );
}

export default Department;
