import moment from "moment";
import React, { useEffect, Fragment, useState } from "react";
import "./Report.css";
import _ from "underscore";
import { Bar, Pie } from "react-chartjs-2";
import { message, Table } from "antd";
import "chartjs-plugin-datalabels";
import axios from "axios";
import { url } from "../../constant/url";

function Report(props) {
  const [daily, setDaily] = useState([]);
  const [weekly, setWeekly] = useState([]);
  const [dataLast, setDataLast] = useState([]);
  const [dataChart, setDataChart] = useState({
    data_bar: [],
    data_pie: {
      label: [],
      percentage: [],
    },
  });

  useEffect(() => {
    getReportCalendarDaily();
  }, []);

  useEffect(() => {
    getReportCalendarWeekly();
  }, []);

  useEffect(() => {
    getDataLastReport();
  }, []);

  useEffect(() => {
    getChartReport();
  }, []);

  const daysInMonth = (iMonth, iYear) => {
    return 32 - new Date(iYear, iMonth, 32).getDate();
  };

  const checkTime = (time) => {
    if (time < 10) {
      time = "0" + time;
    } // add zero in front of numbers < 10
    return time;
  };

  const showCalendar = () => {
    const year = new Date().getFullYear();
    const month = new Date().getMonth();

    let firstDay = new Date(year, month).getDay();

    const cells = [];
    let date = 1;
    for (let i = 0; i < 6; i++) {
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < firstDay) {
          cells.push({ date: null, month: month + 1, year });
        } else if (date > daysInMonth(month, year)) {
          break;
        } else {
          cells.push({ date: checkTime(date), month: month + 1, year });
          date++;
        }
      }
    }

    return cells;
  };

  const getReportCalendarDaily = () => {
    const queryParam = new URLSearchParams(props.location.search);
    axios
      .get(`${url}/report/daily`, {
        params: {
          user_id: queryParam.get("userId"),
        },
      })
      .then((res) => {
        setDaily(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
  };

  const getReportCalendarWeekly = () => {
    const queryParam = new URLSearchParams(props.location.search);
    axios
      .get(`${url}/report/week`, {
        params: {
          user_id: queryParam.get("userId"),
        },
      })
      .then((res) => {
        setWeekly(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
  };

  const getDataLastReport = () => {
    const queryParam = new URLSearchParams(props.location.search);
    axios
      .get(`${url}/report/last-update`, {
        params: {
          user_id: queryParam.get("userId"),
        },
      })
      .then((res) => {
        setDataLast(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
  };

  const getChartReport = () => {
    const queryParam = new URLSearchParams(props.location.search);
    axios
      .get(`${url}/report/chart`, {
        params: {
          user_id: queryParam.get("userId"),
        },
      })
      .then((res) => {
        setDataChart(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
  };

  const columns = [
    {
      title: "Main Process",
      dataIndex: "main_process_name",
      key: "main_process_name",
      sorter: false,
    },
    {
      title: "Line Process",
      dataIndex: "line_process_name",
      key: "line_process_name",
      sorter: false,
    },
    {
      title: "Part no",
      dataIndex: "part_number",
      key: "part_number",
      sorter: false,
    },
    {
      title: "Daily Plan",
      dataIndex: "plan",
      key: "plan",
      sorter: false,
    },
    {
      title: "Daily Report",
      dataIndex: "daily_report",
      key: "daily_report",
      sorter: false,
      children: [
        {
          title: "Plan",
          dataIndex: "plan",
          key: "plan",
          sorter: false,
        },
        {
          title: "Actual",
          dataIndex: "actual",
          key: "actual",
          sorter: false,
        },
        {
          title: "Balance",
          dataIndex: "balance",
          key: "balance",
          sorter: false,
        },
      ],
    },
    {
      title: "Accumulation",
      dataIndex: "accumulation",
      key: "accumulation",
      sorter: false,
      children: [
        {
          title: "Plan",
          dataIndex: "plan_acc",
          key: "plan_acc",
          sorter: false,
        },
        {
          title: "Actual",
          dataIndex: "actual_acc",
          key: "actual_acc",
          sorter: false,
        },
        {
          title: "Balance",
          dataIndex: "balance_acc",
          key: "balance_acc",
          sorter: false,
        },
      ],
    },
    {
      title: "CT Part",
      dataIndex: "balance_accumulation",
      key: "balance_accumulation",
      sorter: false,
      children: [
        {
          title: "Time/Second",
          dataIndex: "time_per_seconds",
          key: "time_per_seconds",
          sorter: false,
        },
        {
          title: "Hour/Pieces",
          dataIndex: "hour_per_pieces",
          key: "hour_per_pieces",
          sorter: false,
        },
      ],
    },
    {
      title: "Downtime Information",
      dataIndex: "breakdown",
      key: "breakdown",
      sorter: false,
      children: [
        {
          title: "Remarks Downtime",
          dataIndex: "breakdown",
          key: "breakdown",
          sorter: false,
          render: (data) => _.pluck(data, "breakdown_name").join(", "),
        },
        {
          title: "Percentage",
          dataIndex: "percentage",
          key: "percentage",
          sorter: false,
        },
      ],
    },
  ];

  return (
    <div className="report-content-wrapper">
      <div className="report-content">
        <div>
          <h1>Daily Percentage Eff. WH</h1>
          <table className="table">
            <tbody>
              {_.chunk(showCalendar(), 7).map((chunk, index) => {
                return (
                  <Fragment key={index}>
                    <tr className="date">
                      {chunk.map((date, index) => {
                        return (
                          <td key={index}>
                            {date.date
                              ? moment(date.date, "DD").format("DD-MMM")
                              : ""}
                          </td>
                        );
                      })}
                    </tr>
                    <tr className="date-content">
                      {chunk.map((date, index) => {
                        const findDailyValue =
                          daily &&
                          daily.find((x) => {
                            const checkDate = moment(
                              `${date.date}-${date.month}-${date.year}`,
                              "DD-MM-YYYY"
                            );
                            const checkDate2 = moment(x.date, "DD-MM-YYYY");
                            return checkDate.isSame(checkDate2);
                          });
                        return (
                          <td key={index}>
                            {date.date ? (
                              findDailyValue ? (
                                <strong>{findDailyValue.percentage}%</strong>
                              ) : (
                                <strong>0%</strong>
                              )
                            ) : (
                              ""
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
          <table className="table mt-2">
            <tbody>
              <tr className="date">
                <td rowSpan="2">Weekly</td>
                <td>Week 1</td>
                <td>Week 2</td>
                <td>Week 3</td>
                <td>Week 4</td>
                <td>Week 5</td>
              </tr>
              <tr className="date-content">
                <td>
                  {weekly.find((x) => x.week === 1)
                    ? weekly.find((x) => x.week === 1).percentage
                    : 0}
                  %{" "}
                </td>
                <td>
                  {weekly.find((x) => x.week === 2)
                    ? weekly.find((x) => x.week === 2).percentage
                    : 0}
                  %
                </td>
                <td>
                  {weekly.find((x) => x.week === 3)
                    ? weekly.find((x) => x.week === 3).percentage
                    : 0}
                  %
                </td>
                <td>
                  {weekly.find((x) => x.week === 4)
                    ? weekly.find((x) => x.week === 4).percentage
                    : 0}
                  %
                </td>
                <td>
                  {weekly.find((x) => x.week === 5)
                    ? weekly.find((x) => x.week === 5).percentage
                    : 0}
                  %
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="report-chart">
          <Bar
            data={{
              labels: _.pluck(dataChart.data_bar, "label"),
              datasets: [
                {
                  label: "Weekly Percentage",
                  data: _.pluck(dataChart.data_bar, "percentage"),
                  fill: false,
                  borderColor: "rgb(75, 192, 192)",
                  backgroundColor: "rgb(75, 192, 192)",
                  tension: 0.1,
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  display: true,
                  color: "white",
                },
              },

              scales: {
                y: {
                  ticks: {
                    // Include a dollar sign in the ticks
                    callback: function (value, index, values) {
                      return value + "%";
                    },
                  },
                },
              },
            }}
          />
          <div className="mt-2 report-donut">
            <Pie
              options={{
                plugins: {
                  datalabels: {
                    display: true,
                    color: "white",
                  },
                  legend: {
                    display: false,
                  },
                },
              }}
              data={{
                labels: dataChart.data_pie.label,
                datasets: [
                  {
                    label: "Weekly Percentage",
                    data: dataChart.data_pie.percentage,
                    fill: false,
                    borderColor: [
                      "rgb(26, 188, 156)",
                      "rgb(52, 152, 219)",
                      "rgb(155, 89, 182)",
                      "rgb(230, 126, 34)",
                    ],
                    backgroundColor: [
                      "rgb(26, 188, 156)",
                      "rgb(52, 152, 219)",
                      "rgb(155, 89, 182)",
                      "rgb(230, 126, 34)",
                    ],
                    tension: 0.1,
                    datalabels: {
                      display: true,
                      color: "white",
                    },
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>
      <div className="report-table">
        <div>
          <h1>
            Last update:{" "}
            {dataLast.length > 0
              ? moment(
                  dataLast[dataLast.length - 1].production_time,
                  "YYYY-MM-DD"
                ).format("LLLL")
              : "-"}
          </h1>
          <Table
            columns={columns}
            dataSource={dataLast}
            bordered
            size="small"
          />
        </div>
      </div>
    </div>
  );
}

export default Report;
