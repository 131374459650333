const colors = {
  primary: "#1b2086", // BIru sanoh
  secondary: "#918a8a", //abu-abu
  stroke: "#F1F1F5", //putih
  black: "#4F4F4F", //abu ke hitaman
  active: "#eff2fc", // biru muda ke putih
  background: "#fafbfd", //putih,
  border: "#f2f2f2", //putih
  danger: "#FB0606", //merah
};

export default colors;
