import React from "react";
import BaseLayout from "./components/BaseLayout/BaseLayout";
import FullLayout from "./components/FullLayout/FullLayout";
import Login from "./pages/Login/Login";
import { Redirect } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import User from "./pages/User/User";
import UserForm from "./pages/User/UserForm";
import Role from "./pages/Role/Role";
import RoleForm from "./pages/Role/RoleForm";
import Permission from "./pages/Permission/Permission";
import PermissionForm from "./pages/Permission/PermissionForm";
import Menu from "./pages/Menu/Menu";
import Setting from "./pages/Setting/Setting";
import SettingForm from "./pages/Setting/SettingForm";
import Employee from "./pages/Employee/Employee";
import EmployeeForm from "./pages/Employee/EmployeeForm";
import MainProcess from "./pages/MainProcess/MainProcess";
import MainProcessForm from "./pages/MainProcess/MainProcessForm";
import LineProcess from "./pages/LineProcess/LineProcess";
import LineProcessForm from "./pages/LineProcess/LineProcessForm";
import MappingProcess from "./pages/MappingProcess/MappingProcess";
import MappingProcessForm from "./pages/MappingProcess/MappingProcessForm";
import shift from "./pages/Shift/Shift";
import ShiftForm from "./pages/Shift/ShiftForm";
import Machine from "./pages/Machine/Machine";
import Shift from "./pages/Shift/Shift";
import MachineForm from "./pages/Machine/MachineForm";
import Model from "./pages/Model/Model";
import ModelForm from "./pages/Model/ModelForm";
import Part from "./pages/Part/Part";
import PartForm from "./pages/Part/PartForm";
import Customer from "./pages/Customer/Customer";
import CUstomerFOrm from "./pages/Customer/CustomerForm";
import CustomerForm from "./pages/Customer/CustomerForm";
import ItemCheck from "./pages/ItemCheck/ItemCheck";
import ItemCheckForm from "./pages/ItemCheck/ItemCheckForm";
import Operation from "antd/lib/transfer/operation";
import Operator from "./pages/Operator/Operator";
import OperatorForm from "./pages/Operator/OperatorForm";
import ProductionPlanning from "./pages/ProductionPlanning/ProductionPlanning";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Department from "./pages/Department/Department";
import DepartmentForm from "./pages/Department/DepartmentForm";
import BillOfMaterial from "./pages/BOM/BillOfMaterial";
import BillOfMaterialForm from "./pages/BOM/BillOfMaterialForm";
import Breakdown from "./pages/Breakdown/Breakdown";
import Ng from "./pages/NG/Ng";
import ProductionPlanningForm from "./pages/ProductionPlanning/ProductionPlanningForm";
import TesNG from "./pages/TesNG/TesNG";
import TesNGForm from "./pages/TesNG/TesNGForm";
import CycleTime from "./pages/CycleTime/CycleTime";
import CycleTimeForm from "./pages/CycleTime/CycleTimeForm";
import TesBreakdown from "./pages/TesBreakdown/TesBreakdown";
import TesBreakdownForm from "./pages/TesBreakdown/TesBreakdownForm";
import TesOperator from "./pages/TesOperator/TesOperator";
import TesOperatorForm from "./pages/TesOperator/TesOperatorForm";
import Andon from "./pages/Andon/Andon";
import Report from "./pages/Report/Report";
import cobaDash from "./pages/Dashboard/CobaDash";

const router = [
  {
    path: "/",
    layout: BaseLayout,
    component: () => <Redirect to="/login" />,
  },
  {
    path: "/dashboard",
    layout: BaseLayout,
    component: Dashboard,
  },

  {
    path: "/cobadash",
    layout: BaseLayout,
    component: cobaDash,
  },
  {
    path: "/user",
    layout: BaseLayout,
    component: User,
  },
  {
    path: "/user/create",
    layout: BaseLayout,
    component: UserForm,
  },
  {
    path: "/user/edit/:id",
    layout: BaseLayout,
    component: UserForm,
  },
  {
    path: "/login",
    layout: FullLayout,
    component: Login,
  },
  {
    path: "/role",
    layout: BaseLayout,
    component: Role,
  },
  {
    path: "/role/create",
    layout: BaseLayout,
    component: RoleForm,
  },
  {
    path: "/role/edit/:id",
    layout: BaseLayout,
    component: RoleForm,
  },

  {
    path: "/permission",
    layout: BaseLayout,
    component: Permission,
  },
  {
    path: "/permission/create",
    layout: BaseLayout,
    component: PermissionForm,
  },
  {
    path: "/permission/edit/:id",
    layout: BaseLayout,
    component: PermissionForm,
  },
  {
    path: "/menu",
    layout: BaseLayout,
    component: Menu,
  },
  {
    path: "/setting",
    layout: BaseLayout,
    component: Setting,
  },
  {
    path: "/setting/create",
    layout: BaseLayout,
    component: SettingForm,
  },
  {
    path: "/setting/edit/:id",
    layout: BaseLayout,
    component: SettingForm,
  },
  {
    path: "/employee",
    layout: BaseLayout,
    component: Employee,
  },
  {
    path: "/employee/create",
    layout: BaseLayout,
    component: EmployeeForm,
  },
  {
    path: "/employee/edit/:id",
    layout: BaseLayout,
    component: EmployeeForm,
  },
  {
    path: "/main-process",
    layout: BaseLayout,
    component: MainProcess,
  },
  {
    path: "/main-process/create",
    layout: BaseLayout,
    component: MainProcessForm,
  },
  {
    path: "/main-process/edit/:id",
    layout: BaseLayout,
    component: MainProcessForm,
  },
  {
    path: "/line-process",
    layout: BaseLayout,
    component: LineProcess,
  },
  {
    path: "/line-process/create",
    layout: BaseLayout,
    component: LineProcessForm,
  },
  {
    path: "/line-process/edit/:id",
    layout: BaseLayout,
    component: LineProcessForm,
  },
  {
    path: "/mapping-process",
    layout: BaseLayout,
    component: MappingProcess,
  },
  {
    path: "/mapping-process/create",
    layout: BaseLayout,
    component: MappingProcessForm,
  },
  {
    path: "/mapping-process/edit/:id",
    layout: BaseLayout,
    component: MappingProcessForm,
  },
  {
    path: "/shift",
    layout: BaseLayout,
    component: Shift,
  },
  {
    path: "/shift/create",
    layout: BaseLayout,
    component: ShiftForm,
  },
  {
    path: "/shift/edit/:id",
    layout: BaseLayout,
    component: ShiftForm,
  },
  {
    path: "/machine",
    layout: BaseLayout,
    component: Machine,
  },
  {
    path: "/machine/create",
    layout: BaseLayout,
    component: MachineForm,
  },
  {
    path: "/machine/edit/:id",
    layout: BaseLayout,
    component: MachineForm,
  },
  {
    path: "/model",
    layout: BaseLayout,
    component: Model,
  },
  {
    path: "/model/create",
    layout: BaseLayout,
    component: ModelForm,
  },
  {
    path: "/model/edit/:id",
    layout: BaseLayout,
    component: ModelForm,
  },
  {
    path: "/part",
    layout: BaseLayout,
    component: Part,
  },
  {
    path: "/part/create",
    layout: BaseLayout,
    component: PartForm,
  },
  // {
  //   path: "/part/create/Welding",
  //   layout: BaseLayout,
  //   component: PartForm,
  // },
  // {
  //   path: "/part/create/Flaring",
  //   layout: BaseLayout,
  //   component: PartForm,
  // },
  // {
  //   path: "/part/create/Bending",
  //   layout: BaseLayout,
  //   component: PartForm,
  // },
  // {
  //   path: "/part/create/Cutting",
  //   layout: BaseLayout,
  //   component: PartForm,
  // },
  {
    path: "/part/edit/:id",
    layout: BaseLayout,
    component: PartForm,
  },
  {
    path: "/customer",
    layout: BaseLayout,
    component: Customer,
  },
  {
    path: "/customer/create",
    layout: BaseLayout,
    component: CustomerForm,
  },
  {
    path: "/customer/edit/:id",
    layout: BaseLayout,
    component: CustomerForm,
  },
  {
    path: "/item-check",
    layout: BaseLayout,
    component: ItemCheck,
  },
  {
    path: "/item-check/create",
    layout: BaseLayout,
    component: ItemCheckForm,
  },
  {
    path: "/item-check/edit/:id",
    layout: BaseLayout,
    component: ItemCheckForm,
  },
  {
    path: "/operator",
    layout: BaseLayout,
    component: Operator,
  },
  {
    path: "/operator/create",
    layout: BaseLayout,
    component: OperatorForm,
  },
  {
    path: "/operator/edit/:id",
    layout: BaseLayout,
    component: OperatorForm,
  },
  {
    path: "/production-planning",
    layout: BaseLayout,
    component: ProductionPlanning,
  },
  {
    path: "/production-planning/edit/:id",
    layout: BaseLayout,
    component: ProductionPlanningForm,
  },
  {
    path: "/forgot-password",
    layout: FullLayout,
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    layout: FullLayout,
    component: ResetPassword,
  },
  {
    path: "/department",
    layout: BaseLayout,
    component: Department,
  },
  {
    path: "/department/create",
    layout: BaseLayout,
    component: DepartmentForm,
  },
  {
    path: "/department/edit/:id",
    layout: BaseLayout,
    component: DepartmentForm,
  },
  {
    path: "/bill-of-material",
    layout: BaseLayout,
    component: BillOfMaterial,
  },
  {
    path: "/bill-of-material/create",
    layout: BaseLayout,
    component: BillOfMaterialForm,
  },
  {
    path: "/bill-of-material/edit/:id",
    layout: BaseLayout,
    component: BillOfMaterialForm,
  },
  {
    path: "/breakdown",
    layout: BaseLayout,
    component: Breakdown,
  },

  {
    path: "/ng",
    layout: BaseLayout,
    component: Ng,
  },
  {
    path: "/cycle-time",
    layout: BaseLayout,
    component: CycleTime,
  },
  {
    path: "/cycle-time/create",
    layout: BaseLayout,
    component: CycleTimeForm,
  },
  {
    path: "/cycle-time/edit/:id",
    layout: BaseLayout,
    component: CycleTimeForm,
  },
  {
    path: "/tes-ng",
    layout: BaseLayout,
    component: TesNG,
  },
  {
    path: "/tes-ng/create",
    layout: BaseLayout,
    component: TesNGForm,
  },
  {
    path: "/tes-ng/edit/:id",
    layout: BaseLayout,
    component: TesNGForm,
  },
  {
    path: "/tes-breakdown",
    layout: BaseLayout,
    component: TesBreakdown,
  },
  {
    path: "/tes-breakdown/create",
    layout: BaseLayout,
    component: TesBreakdownForm,
  },
  {
    path: "/tes-breakdown/edit/:id",
    layout: BaseLayout,
    component: TesBreakdownForm,
  },
  {
    path: "/tes-operator",
    layout: BaseLayout,
    component: TesOperator,
  },
  {
    path: "/tes-operator/create",
    layout: BaseLayout,
    component: TesOperatorForm,
  },
  {
    path: "/tes-operator/edit/:id",
    layout: BaseLayout,
    component: TesOperatorForm,
  },

  {
    path: "/andon",
    layout: ({ children }) => <div className="dark-mode">{children}</div>,
    component: Andon,
  },
  {
    path: "/andon/:plan",
    layout: ({ children }) => <div className="dark-mode">{children}</div>,
    component: Andon,
  },
  {
    path: "/andon/:plan/:main",
    layout: ({ children }) => <div className="dark-mode">{children}</div>,
    component: Andon,
  },

  {
    path: "/andon/:plan/:main/:line",
    layout: ({ children }) => <div className="dark-mode">{children}</div>,
    component: Andon,
  },
  {
    path: "/report",
    layout: ({ children }) => <div className="report">{children}</div>,
    component: Report,
  },
];

export default router;
