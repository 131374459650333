import "./Footer.css";

function Footer() {
  return (
    <>
      <span>Copyright © 2023 Kelola Pre Test</span>
      {/* <span className="kelola-heart">
        <i className="mdi mdi-heart" />
      </span>
      <span>in Karawang</span> */}
    </>
  );
}

export default Footer;
