import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Table,
  Space,
  DatePicker,
  Tag,
  Upload,
  Card,
} from "antd";
import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { url } from "../../constant/url";
import {
  EditOutlined,
  DeleteOutlined,
  ExportOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import moment from "moment";
const _ = require("underscore");

// import DatePicker from "react-horizontal-datepicker";
const { width } = window.screen;

function ProductionPlanning(history) {
  const [total, setTotal] = useState([]);
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [error, setError] = useState(null);
  const [productionPlannings, setProductionPlannings] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [data, setData] = useState({
    start_month: moment().startOf("month"),
    department: "",
    main_process_name: "",
    main_process_id: "",
    line_process_id: "",
    line_process_name: "",
    part_number: "",
  });

  useEffect(() => {
    getData();
  }, [keyword, page, perpage, sort, data.start_month]);

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/production-planning`, {
        params: {
          keyword,
          columns: [
            "department",
            "main_process_name",
            "main_process_id",
            "line_process_id",
            "line_process_name",
            "part_id",
            "part_number",
            "part_name",
            "start_month",
          ],
          perpage: perpage,
          page,
          sort: sort.sort,
          order: sort.order,
          month: moment(data.start_month).format("M"),
          year: moment(data.start_month).format("YYYY"),
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setProductionPlannings(res.data);
        setTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure ?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    axios
      .delete(`${url}/production-planning/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPerpage(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeMonth = (value) => {
    if (value) {
      setData({
        ...data,
        start_month: value,
      });
    } else {
      setData({
        ...data,
        start_month: undefined,
      });
    }
  };

  const handleChangeRow = (name, value, id) => {
    const index = data.findIndex((x) => x._id === id);
    const newItem = [...data];
    newItem[index][name] = value;
    setData(newItem);
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    axios
      .post(`${url}/production-planning/import`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const handleRemove = (item) => {
    Modal.confirm({
      title: "Are you sure ?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(item);
      },
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  console.log("tes", moment(data.start_month).format("M YYYY"));

  const columns = [
    {
      title: "Department",
      dataIndex: "plan_name",
      key: "plan_name",
      sorter: true,
      width: 100,
      // fixed: "right",
    },
    {
      title: "Main Process Name",
      dataIndex: "main_process_name",
      key: "main_process_name",
      width: 115,
      sorter: false,
    },
    {
      title: "Line Process Name",
      dataIndex: "line_process_name",
      key: "line_process_name",
      width: 115,
      sorter: false,
    },

    {
      title: "Part Name",
      dataIndex: "part_name",
      key: "part_name",
      width: 100,
      sorter: false,
    },
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
      width: 100,
      sorter: false,
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      width: 100,
      sorter: false,
    },
    // {
    //   title: "a",
    //   dataIndex: "date",
    //   // title: moment(data.start_month).format("MMMM YYYY"),
    //   // children: [...date],
    //   render: (date, item) => (
    //     <Input
    //       allowClear="true"
    //       value={date}
    //       onChange={(e) => handleChangeRow("date", e.target.value, item._id)}
    //     />
    //   ),
    // },
    {
      title: moment(data.start_month).format("MMMM YYYY"),
      children: [
        {
          title: "1 ",
          dataIndex: "one",
          sorter: false,
        },
        {
          title: "2 ",
          dataIndex: "two",
          sorter: false,
        },
        {
          title: "3 ",
          dataIndex: "three",
          sorter: false,
        },
        {
          title: "4 ",
          dataIndex: "four",
          sorter: false,
        },
        {
          title: "5 ",
          dataIndex: "five",
          sorter: false,
        },
        {
          title: "6 ",
          dataIndex: "six",
          sorter: false,
        },
        {
          title: "7 ",
          dataIndex: "seven",
          sorter: false,
        },
        {
          title: "8 ",
          dataIndex: "eight",
          sorter: false,
        },
        {
          title: "9 ",
          dataIndex: "nine",
          sorter: false,
        },
        {
          title: "10 ",
          dataIndex: "ten",
          sorter: false,
        },
        {
          title: "11 ",
          dataIndex: "eleven",
          sorter: false,
        },

        {
          title: "12 ",
          dataIndex: "twelve",
          sorter: false,
        },
        {
          title: "13 ",
          dataIndex: "thirteen",
          sorter: false,
        },
        {
          title: "14 ",
          dataIndex: "fourteen",
          sorter: false,
        },
        {
          title: "15 ",
          dataIndex: "fiveteen",
          sorter: false,
        },
        {
          title: "16 ",
          dataIndex: "sixteen",
          sorter: false,
        },
        {
          title: "17 ",
          dataIndex: "seventeen",
          sorter: false,
        },
        {
          title: "18 ",
          dataIndex: "eighteen",
          sorter: false,
        },
        {
          title: "19 ",
          dataIndex: "nineteen",
          sorter: false,
        },
        {
          title: "20 ",
          dataIndex: "twenty",
          sorter: false,
        },
        {
          title: "21 ",
          dataIndex: "twenty_one",
          sorter: false,
        },
        {
          title: "22 ",
          dataIndex: "twenty_two",
          sorter: false,
        },
        {
          title: "23 ",
          dataIndex: "twenty_three",
          sorter: false,
        },
        {
          title: "24 ",
          dataIndex: "twenty_four",
          sorter: false,
        },
        {
          title: "25 ",
          dataIndex: "twenty_five",
          sorter: false,
        },
        {
          title: "26 ",
          dataIndex: "twenty_six",
          sorter: false,
        },
        {
          title: "27 ",
          dataIndex: "twenty_seven",
          sorter: false,
        },
        {
          title: "28 ",
          dataIndex: "twenty_eight",
          sorter: false,
        },
        {
          title: "29 ",
          dataIndex: "twenty_nine",
          sorter: false,
        },
        {
          title: "30 ",
          dataIndex: "thirty",
          sorter: false,
        },

        {
          title: "31 ",
          dataIndex: "thirty_one",
          sorter: false,
        },
      ],
    },

    {
      title: "",
      dataIndex: "_id",
      key: "id",
      sorter: false,
      fixed: "right",
      width: 50,
      render: (id) => {
        return (
          <>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Link to={`/production-planning/edit/${id}`}>
                      {/* <i className="mdi mdi-pencil mr-5" /> Edit */}
                      <EditOutlined /> Edit
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <span onClick={() => handleDelete(id)}>
                      {/* <i className="mdi mdi-delete mr-5" /> */}
                      <DeleteOutlined />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <i className="mdi mdi-dots-vertical" />
              </span>
            </Dropdown>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <ul className="breadcumb">
        <li className="active">Production Planning</li>
      </ul>
      <div className="content">
        <Row justify="space-between">
          <Col>
            <Button
              onClick={showModal}
              type="primary"
              style={{
                backgroundColor: "#1b2086",
                borderColor: "#1b2086",
                marginLeft: 10,
              }}
            >
              <ImportOutlined />
              Import
            </Button>
          </Col>

          <Modal
            title="Import Production Planning"
            visible={isModalVisible}
            footer={null}
            onCancel={closeModal}
            onCancel={handleCancel}
          >
            <Card className="body-data">
              <div name="control-hooks">
                <div name="file" label="File">
                  <Fragment>
                    <Upload
                      onRemove={handleRemove}
                      beforeUpload={handleBeforeUpload}
                      fileList={[]}
                    >
                      <Button style={{ marginBottom: 10 }}>
                        Choose File <ExportOutlined />
                      </Button>
                    </Upload>
                    {data.file && (
                      <React.Fragment
                        children={[
                          <Button onClick={handleRemove} type="danger" ghost>
                            <DeleteOutlined />
                          </Button>,
                        ]}
                      >
                        {data.file.name ? data.file.name : data.file}
                      </React.Fragment>
                    )}
                  </Fragment>
                </div>
                <div>
                  <Button
                    loading={loading}
                    onClick={onSaveData}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                    style={{
                      marginRight: 10,
                      backgroundColor: "#1b2086",
                      borderColor: "#1b2086",
                    }}
                  >
                    Upload
                  </Button>
                  <Button htmlType="reset" onClick={handleRemove}>
                    Reset
                  </Button>
                </div>
              </div>
            </Card>
          </Modal>

          {/* <Col xs={24} md={12} lg={18} /> */}
          <Col xs={24} md={24} lg={6} className="align-right">
            <Space direction="vertical" size={12}>
              <DatePicker
                style={{ marginTop: 10 }}
                onChange={handleChangeMonth}
                picker="month"
                value={data.start_month}
                format="MMMM YYYY"
              />
            </Space>
            {/* </Col>
        <Col> */}
            <Input.Search
              style={{ marginTop: 10 }}
              allowClear="true"
              onSearch={(value) => setKeyword(value)}
              placeholder="Search..."
            />
          </Col>
        </Row>
        <Row className="mt-40">
          <Col xs={24}>
            <Table
              style={{ maxWidth: width - 185 }}
              scroll={{ x: 2500 }}
              columns={columns}
              dataSource={productionPlannings.data}
              pagination={{ total, showSizeChanger: true }}
              loading={loading}
              rowKey={(data) => data._id}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ProductionPlanning;
