import {
  Button,
  Col,
  Input,
  message,
  Row,
  Select,
  Table,
  DatePicker,
  Form,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./ProductionPlanningForm.css";
import axios from "axios";
import { url } from "../../constant/url";
import { LeftOutlined } from "@ant-design/icons";
import ProductionPlanning from "./ProductionPlanning";
import moment from "moment";

const { Option } = Select;
function ProductionPlanningForm({ history, match }) {
  const [data, setData] = useState({
    plan_id: "",
    plan_name: "",
    line_process_id: "",
    line_process_name: "",
    main_process_id: "",
    main_process_name: "",
    part_id: "",
    part_number: "",
    month: "",
    year: "",
  });

  const [page] = useState(1);
  const [perpage] = useState(10);
  const [sort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [lines, setLines] = useState([]);
  const [mains, setMains] = useState([]);
  const [parts, setParts] = useState([]);
  const [productionPlannings, setProductionPlannings] = useState([]);

  useEffect(() => {
    if (match.params.id) {
      getData();
    }
  }, []);

  const handleChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });

    setData({
      ...data,
      [name]: value,
    });
  };

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/production-planning/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const {
          plan_id,
          plan_name,
          line_process_id,
          line_process_name,
          line_processes,
          main_process_id,
          main_process_name,
          main_processes,
          part_id,
          part_number,
          part_name,
          month,
          year,
          one,
          two,
          three,
          four,
          five,
          six,
          seven,
          eight,
          nine,
          ten,
          eleven,
          twelve,
          thirteen,
          fourteen,
          fiveteen,
          sixteen,
          seventeen,
          eighteen,
          nineteen,
          twenty,
          twenty_one,
          twenty_two,
          twenty_three,
          twenty_four,
          twenty_five,
          twenty_six,
          twenty_seven,
          twenty_eight,
          twenty_nine,
          thirty,
          thirty_one,
        } = res.data.data;
        setData({
          ...data,
          plan_id,
          plan_name,
          line_process_id,
          line_process_name,
          main_process_id,
          main_process_name,
          part_id,
          part_number,
          part_name,
          month,
          year,
        });

        setProductionPlannings([
          {
            one,
            two,
            three,
            four,
            five,
            six,
            seven,
            eight,
            nine,
            ten,
            eleven,
            twelve,
            thirteen,
            fourteen,
            fiveteen,
            sixteen,
            seventeen,
            eighteen,
            nineteen,
            twenty,
            twenty_one,
            twenty_two,
            twenty_three,
            twenty_four,
            twenty_five,
            twenty_six,
            twenty_seven,
            twenty_eight,
            twenty_nine,
            thirty,
            thirty_one,
          },
        ]);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      plan_id: "",
      plan_name: "",
      line_process_id: "",
      line_process_name: "",
      line_processes: "",
      main_process_id: "",
      main_process_name: "",
      line_processes: "",
      part_id: "",
      part_number: "",
      month: "",
      year: "",
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        match.params.id
          ? `${url}/production-planning/${match.params.id}`
          : `${url}/production-planning`,
        {
          plan_id: data.plan_id,
          plan_name: data.plan_name,
          line_process_id: data.line_process_id,
          line_process_name: data.line_process_name,
          // line_processes: data.line_processes,
          main_process_id: data.main_process_id,
          main_process_name: data.main_process_name,
          // line_processes: data.line_processes,
          part_id: data.part_id,
          part_number: data.part_number,
          month: data.month,
          year: data.year,
          ...productionPlannings[0],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/production-planning");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  console.log(errors);

  const handleChangeSelect = (name, value) => {
    setErrors(null);
    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
        [`${name}_number`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
        [`${name}_number`]: null,
      });
    }
  };

  const getDepartment = async (keyword) => {
    await axios
      .get(`${url}/mapping-process/list-plan`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setDepartments(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getMain = async (keyword) => {
    await axios
      .get(`${url}/mapping-process/list-main`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
          plan_id: data.plan_id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setMains(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getLine = async (keyword) => {
    await axios
      .get(`${url}/mapping-process/list-line-one`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
          plan_id: data.plan_id,
          main_process_id: data.main_process_id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLines(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getPart = async (keyword) => {
    await axios
      .get(`${url}/part/list`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setParts(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeRow = (name, value, id) => {
    const index = productionPlannings.findIndex((x) => x._id === id);
    const newitems = [...productionPlannings];
    newitems[index][name] = value;
    setProductionPlannings(newitems);
  };

  function onChange(date, dateString) {
    console.log(date, dateString);
  }

  const columns = [
    {
      title: "1 ",
      dataIndex: "one",
      sorter: false,
      render: (one, data) => (
        <Input
          allowClear="true"
          value={one}
          onChange={(e) => handleChangeRow("one", e.target.value, data._id)}
        />
      ),
    },
    {
      title: "2 ",
      dataIndex: "two",
      sorter: false,
      render: (two, data) => (
        <Input
          allowClear="true"
          value={two}
          onChange={(e) => handleChangeRow("two", e.target.value, data._id)}
        />
      ),
    },
    {
      title: "3 ",
      dataIndex: "three",
      sorter: false,
      render: (three, data) => (
        <Input
          allowClear="true"
          value={three}
          onChange={(e) => handleChangeRow("three", e.target.value, data._id)}
        />
      ),
    },
    {
      title: "4 ",
      dataIndex: "four",
      sorter: false,
      render: (four, data) => (
        <Input
          allowClear="true"
          value={four}
          onChange={(e) => handleChangeRow("four", e.target.value, data._id)}
        />
      ),
    },
    {
      title: "5 ",
      dataIndex: "five",
      sorter: false,
      render: (five, data) => (
        <Input
          allowClear="true"
          value={five}
          onChange={(e) => handleChangeRow("five", e.target.value, data._id)}
        />
      ),
    },
    {
      title: "6 ",
      dataIndex: "six",
      sorter: false,
      render: (six, data) => (
        <Input
          allowClear="true"
          value={six}
          onChange={(e) => handleChangeRow("six", e.target.value, data._id)}
        />
      ),
    },
    {
      title: "7 ",
      dataIndex: "seven",
      sorter: false,
      render: (seven, data) => (
        <Input
          allowClear="true"
          value={seven}
          onChange={(e) => handleChangeRow("seven", e.target.value, data._id)}
        />
      ),
    },
    {
      title: "8 ",
      dataIndex: "eight",
      sorter: false,
      render: (eight, data) => (
        <Input
          allowClear="true"
          value={eight}
          onChange={(e) => handleChangeRow("eight", e.target.value, data._id)}
        />
      ),
    },
    {
      title: "9 ",
      dataIndex: "nine",
      sorter: false,
      render: (nine, data) => (
        <Input
          allowClear="true"
          value={nine}
          onChange={(e) => handleChangeRow("nine", e.target.value, data._id)}
        />
      ),
    },
    {
      title: "10 ",
      dataIndex: "ten",
      sorter: false,
      render: (ten, data) => (
        <Input
          allowClear="true"
          value={ten}
          onChange={(e) => handleChangeRow("ten", e.target.value, data._id)}
        />
      ),
    },
    {
      title: "11 ",
      dataIndex: "eleven",
      sorter: false,
      render: (eleven, data) => (
        <Input
          allowClear="true"
          value={eleven}
          onChange={(e) => handleChangeRow("eleven", e.target.value, data._id)}
        />
      ),
    },

    {
      title: "12 ",
      dataIndex: "twelve",
      sorter: false,
      render: (twelve, data) => (
        <Input
          allowClear="true"
          value={twelve}
          onChange={(e) => handleChangeRow("twelve", e.target.value, data._id)}
        />
      ),
    },
    {
      title: "13 ",
      dataIndex: "thirteen",
      sorter: false,
      render: (thirteen, data) => (
        <Input
          allowClear="true"
          value={thirteen}
          onChange={(e) =>
            handleChangeRow("thirteen", e.target.value, data._id)
          }
        />
      ),
    },
    {
      title: "14 ",
      dataIndex: "fourteen",
      sorter: false,
      render: (fourteen, data) => (
        <Input
          allowClear="true"
          value={fourteen}
          onChange={(e) =>
            handleChangeRow("fourteen", e.target.value, data._id)
          }
        />
      ),
    },
    {
      title: "15 ",
      dataIndex: "fiveteen",
      sorter: false,
      render: (fiveteen, data) => (
        <Input
          allowClear="true"
          value={fiveteen}
          onChange={(e) =>
            handleChangeRow("fiveteen", e.target.value, data._id)
          }
        />
      ),
    },
    {
      title: "16 ",
      dataIndex: "sixteen",
      sorter: false,

      render: (sixteen, data) => (
        <Input
          allowClear="true"
          value={sixteen}
          onChange={(e) => handleChangeRow("sixteen", e.target.value, data._id)}
        />
      ),
    },
    {
      title: "17 ",
      dataIndex: "seventeen",
      sorter: false,
      render: (seventeen, data) => (
        <Input
          allowClear="true"
          value={seventeen}
          onChange={(e) =>
            handleChangeRow("seventeen", e.target.value, data._id)
          }
        />
      ),
    },
    {
      title: "18 ",
      dataIndex: "eighteen",
      sorter: false,
      render: (eighteen, data) => (
        <Input
          allowClear="true"
          value={eighteen}
          onChange={(e) =>
            handleChangeRow("eighteen", e.target.value, data._id)
          }
        />
      ),
    },
    {
      title: "19 ",
      dataIndex: "nineteen",
      sorter: false,
      render: (nineteen, data) => (
        <Input
          allowClear="true"
          value={nineteen}
          onChange={(e) =>
            handleChangeRow("nineteen", e.target.value, data._id)
          }
        />
      ),
    },
    {
      title: "20 ",
      dataIndex: "twenty",
      sorter: false,
      render: (twenty, data) => (
        <Input
          allowClear="true"
          value={twenty}
          onChange={(e) => handleChangeRow("twenty", e.target.value, data._id)}
        />
      ),
    },
    {
      title: "21 ",
      dataIndex: "twenty_one",
      sorter: false,
      render: (twenty_one, data) => (
        <Input
          allowClear="true"
          value={twenty_one}
          onChange={(e) =>
            handleChangeRow("twenty_one", e.target.value, data._id)
          }
        />
      ),
    },
    {
      title: "22 ",
      dataIndex: "twenty_two",
      sorter: false,
      render: (twenty_two, data) => (
        <Input
          allowClear="true"
          value={twenty_two}
          onChange={(e) =>
            handleChangeRow("twenty_two", e.target.value, data._id)
          }
        />
      ),
    },
    {
      title: "23 ",
      dataIndex: "twenty_three",
      sorter: false,
      render: (twenty_three, data) => (
        <Input
          allowClear="true"
          value={twenty_three}
          onChange={(e) =>
            handleChangeRow("twenty_three", e.target.value, data._id)
          }
        />
      ),
    },
    {
      title: "24 ",
      dataIndex: "twenty_four",
      sorter: false,
      render: (twenty_four, data) => (
        <Input
          allowClear="true"
          value={twenty_four}
          onChange={(e) =>
            handleChangeRow("twenty_four", e.target.value, data._id)
          }
        />
      ),
    },
    {
      title: "25 ",
      dataIndex: "twenty_five",
      sorter: false,
      render: (twenty_five, data) => (
        <Input
          allowClear="true"
          value={twenty_five}
          onChange={(e) =>
            handleChangeRow("twenty_five", e.target.value, data._id)
          }
        />
      ),
    },
    {
      title: "26 ",
      dataIndex: "twenty_six",
      sorter: false,
      render: (twenty_six, data) => (
        <Input
          allowClear="true"
          value={twenty_six}
          onChange={(e) =>
            handleChangeRow("twenty_six", e.target.value, data._id)
          }
        />
      ),
    },
    {
      title: "27 ",
      dataIndex: "twenty_seven",
      sorter: false,
      render: (twenty_seven, data) => (
        <Input
          allowClear="true"
          value={twenty_seven}
          onChange={(e) =>
            handleChangeRow("twenty_seven", e.target.value, data._id)
          }
        />
      ),
    },
    {
      title: "28 ",
      dataIndex: "twenty_eight",
      sorter: false,
      render: (twenty_eight, data) => (
        <Input
          allowClear="true"
          value={twenty_eight}
          onChange={(e) =>
            handleChangeRow("twenty_eight", e.target.value, data._id)
          }
        />
      ),
    },
    {
      title: "29 ",
      dataIndex: "twenty_nine",
      sorter: false,
      render: (twenty_nine, data) => (
        <Input
          allowClear="true"
          value={twenty_nine}
          onChange={(e) =>
            handleChangeRow("twenty_nine", e.target.value, data._id)
          }
        />
      ),
    },
    {
      title: "30 ",
      dataIndex: "thirty",
      sorter: false,
      render: (thirty, data) => (
        <Input
          allowClear="true"
          value={thirty}
          onChange={(e) => handleChangeRow("thirty", e.target.value, data._id)}
        />
      ),
    },

    {
      title: "31 ",
      dataIndex: "thirty_one",
      sorter: false,
      render: (thirty_one, data) => (
        <Input
          allowClear="true"
          value={thirty_one}
          onChange={(e) =>
            handleChangeRow("thirty_one", e.target.value, data._id)
          }
        />
      ),
    },
  ];

  return (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/production-planning">
            <LeftOutlined />
            Production Planning
          </Link>
        </li>
        <li className="active">
          {match.params.id
            ? "Edit Production Planning"
            : "Add Production Planning"}
        </li>
      </ul>
      <div className="content">
        <form onSubmit={handleSave}>
          <Row gutter={20}>
            <Col md={18} xs={24}>
              <div className="form-group">
                Department <span className="error-text">*</span>
                <Select
                  style={{ width: 995 }}
                  labelInValue
                  onChange={(value) => handleChangeSelect("plan", value)}
                  onFocus={() => getDepartment("")}
                  showSearch
                  onSearch={(value) => getDepartment(value)}
                  filterOption={false}
                  value={{
                    key: data.plan_id,
                    label: data.plan_name,
                  }}
                >
                  {departments &&
                    departments.map((dept) => {
                      return (
                        <Option value={dept._id} key={dept._id}>
                          {dept.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.plan_name && (
                  <span className="error-text">{errors.plan_name[0]}</span>
                )}
              </div>

              <div className="form-group">
                Main Process <span className="error-text">*</span>
                <Select
                  style={{ width: 995 }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelect("main_process", value)
                  }
                  onFocus={() => getMain("")}
                  // onFocus={() => getMain()}
                  showSearch
                  onSearch={(value) => getMain(value)}
                  filterOption={false}
                  value={{
                    key: data.main_process_id,
                    label: data.main_process_name,
                  }}
                  // value={data.main_processes}
                >
                  {mains &&
                    mains.map((main) => {
                      return (
                        <Option
                          value={main.main_process_id}
                          key={main.main_process_id}
                        >
                          {main.main_process_name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.main_process_main_process_name && (
                  <span className="error-text">
                    {errors.main_process_name[0]}
                  </span>
                )}
              </div>

              <div className="form-group">
                Line Process <span className="error-text">*</span>
                <Select
                  style={{ width: 995 }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelect("line_process", value)
                  }
                  onFocus={() => getLine("")}
                  // onFocus={() => getLine()}
                  showSearch
                  onSearch={(value) => getLine(value)}
                  filterOption={false}
                  value={{
                    key: data.line_process_id,
                    label: data.line_process_name,
                  }}
                  // value={data.line_processes}
                >
                  {lines &&
                    lines.map((line) => {
                      return (
                        <Option
                          value={line.line_process_id}
                          key={line.line_process_id}
                        >
                          {line.line_process_name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.line_process_name && (
                  <span className="error-text">
                    {errors.line_process_name[0]}
                  </span>
                )}
              </div>

              <div className="form-group">
                Part Name <span className="error-text">*</span>
                <Select
                  style={{ width: 995 }}
                  labelInValue
                  onChange={(value) => handleChangeSelect("part", value)}
                  onFocus={() => getPart("")}
                  showSearch
                  onSearch={(value) => getPart(value)}
                  filterOption={false}
                  value={{
                    key: data.part_id,
                    label: data.part_name,
                  }}
                >
                  {parts &&
                    parts.map((part) => {
                      return (
                        <Option value={part._id} key={part._id}>
                          {part.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.part_number && (
                  <span className="error-text">{errors.part_number[0]}</span>
                )}
              </div>

              <div className="form-group">
                <label className="form-label">
                  Month <span className="error-text">*</span>
                </label>

                {/* <DatePicker
                  style={{ width: 995 }}
                  onChange={(value) => handleChange("month", value)}
                  format="MM"
                  picker="month"
                  // value={data.month}
                /> */}
                <Input
                  value={data.month}
                  className={errors && errors.month ? "is-error" : ""}
                  onChange={(e) => handleChange("month", e.target.value)}
                />
                {errors && errors.month && (
                  <span className="error-text">{errors.month[0]}</span>
                )}
              </div>

              <div className="form-group">
                <label className="form-label">
                  Year <span className="error-text">*</span>
                </label>
                {/* <DatePicker
                  style={{ width: 995 }}
                  onChange={(value) => handleChange("year", value)}
                  format="yyyy"
                  picker="year"
                  // value={data.year}
                /> */}
                <Input
                  value={data.year}
                  className={errors && errors.year ? "is-error" : ""}
                  onChange={(e) => handleChange("year", e.target.value)}
                />
                {errors && errors.year && (
                  <span className="error-text">{errors.year[0]}</span>
                )}
              </div>

              <Table
                size="large"
                columns={columns}
                rowKey={(data, index) => index}
                dataSource={productionPlannings}
                scroll={{ x: 3000 }}
                style={{
                  maxWidth: 980,
                  marginBottom: 20,
                }}
              />
              <div className="form-group">
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={loading}
                  style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}
                >
                  Submit
                </Button>

                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={handleReset}
                  style={{
                    backgroundColor: "#1b2086",
                    marginLeft: 10,
                    borderColor: "#1b2086",
                  }}
                >
                  Reset
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default ProductionPlanningForm;
