import {
  Button,
  Col,
  Input,
  message,
  Row,
  Table,
  Popconfirm,
  Form,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./BillOfMaterialForm.css";
import axios from "axios";
import { url } from "../../constant/url";
import { LeftOutlined } from "@ant-design/icons";

const { Option } = Select;

function BillOfMaterialForm({ history, match }) {
  const [data, setData] = useState({
    part_id: "",
    part_name: "",
    part_number: "",
    partDetails: "",
    weight: "",
    height: "",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [parts, setParts] = useState([]);
  const [partDetails, setPartDetails] = useState([]);

  useEffect(() => {
    if (match.params.id) {
      getData();
    }
  }, []);

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/bill-of-material/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const { part_id, part_number, part_name } = res.data.data;
        setData({
          ...data,
          part_id,
          part_number,
          part_name,
        });
        setPartDetails(
          res.data.data.bill_of_material_detail.map((detail) => {
            return {
              id: detail._id,
              ...detail,
            };
          })
        );
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      part_id: "",
      part_name: "",
      part_number: "",
      partDetails: "",
      weight: "",
      length: "",
    });
    handleDelete();
  };

  const handleDelete = (id) => {
    const details = partDetails.filter((partDetails) => partDetails._id !== id);
    setPartDetails(details);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);
    // console.log(data);
    axios
      .post(
        match.params.id
          ? `${url}/bill-of-material/${match.params.id}`
          : `${url}/bill-of-material`,
        {
          part_id: data.part_id,
          part_number: data.part_number,
          part_name: data.part_name,
          partDetails,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )

      .then((res) => {
        message.success(res.data.message);
        history.push("/bill-of-material");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const getPart = async (keyword) => {
    await axios
      .get(`${url}/part/list`, {
        params: {
          keyword,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setParts(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelectPart = (name, value) => {
    setErrors(null);
    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
        [`${name}_number`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
        [`${name}_number`]: null,
      });
    }
  };

  const handleAddRow = () => {
    setPartDetails([
      ...partDetails,
      {
        id: Math.random(),
        part_id: "",
        part_name: "",
        weight: "",
        length: "",
        // conversion: "",
      },
    ]);
  };

  const handleChangeSelectRow = (name, value, id) => {
    const index = partDetails.findIndex((x) => x.id === id);
    const newPartDetails = [...partDetails];
    if (value) {
      newPartDetails[index][`${name}_id`] = value.value;
      newPartDetails[index][`${name}_name`] = value.label;
      newPartDetails[index][`${name}_number`] = value.label;
      setPartDetails(newPartDetails);
    } else {
      newPartDetails[index][`${name}_id`] = null;
      newPartDetails[index][`${name}_name`] = null;
      newPartDetails[index][`${name}_number`] = null;
      setPartDetails(newPartDetails);
    }
    // newPartDetails[index][`${name}_id`] = value.value;
    // newPartDetails[index][`${name}_name`] = value.label;
    // newPartDetails[index][`${name}_number`] = value.label;
    // setPartDetails(newPartDetails);
  };

  const handleChangeRow = (name, value, id) => {
    const index = partDetails.findIndex((x) => x.id === id);
    const newPartDetails = [...partDetails];
    newPartDetails[index][name] = value;
    setPartDetails(newPartDetails);
  };

  return (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/bill-of-material">
            <LeftOutlined />
            Bill Of Material
          </Link>
        </li>
        <li className="active">
          {match.params.id ? "Edit Bill Of Material" : "Add Bill Of Material"}
        </li>
      </ul>
      <div className="content">
        <form onSubmit={handleSave}>
          <Row gutter={20}>
            <Col md={18} xs={24}>
              <div className="form-group">
                Part Number <span className="error-text">*</span>
                <Select
                  allowClear
                  style={{ width: 693 }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelectPart("part", value, data._id)
                  }
                  onFocus={() => getPart("")}
                  showSearch
                  onSearch={(value) => getPart(value)}
                  filterOption={false}
                  value={{
                    key: data.part_id,
                    label: data.part_number,
                  }}
                >
                  {parts &&
                    parts.map((part) => {
                      return (
                        <Option value={part._id} key={part._id}>
                          {part.number}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.part_number && (
                  <span className="error-text">{errors.part_number[0]}</span>
                )}
              </div>
            </Col>
          </Row>
          <Col sm={24}>
            <Form label="Child">
              <Button
                style={{
                  backgroundColor: "#1b2086",
                  borderColor: "#1b2086",
                }}
                onClick={handleAddRow}
                type="primary"
              >
                Add Child
              </Button>

              <Table
                style={{ marginTop: 30 }}
                rowKey={(row) => row.id}
                columns={[
                  {
                    dataIndex: "part_name",
                    title: "Part Name",
                    sorter: false,
                    render: (part_name, item) => (
                      <Select
                        allowClear
                        style={{ width: 190 }}
                        labelInValue
                        onChange={(value) =>
                          handleChangeSelectRow("part", value, item.id)
                        }
                        onFocus={() => getPart("", "row")}
                        showSearch
                        onSearch={(value) => getPart(value, "row")}
                        filterOption={false}
                        value={{
                          key: item.part_id,
                          label: part_name,
                        }}
                      >
                        {parts &&
                          parts.map((part) => {
                            return (
                              <Option value={part._id} key={part._id}>
                                {part.name}
                              </Option>
                            );
                          })}
                      </Select>
                    ),
                  },
                  // {
                  //   dataIndex: "value",
                  //   title: "Value",
                  //   sorter: false,
                  //   render: (value, item) => (
                  //     <Input
                  //       style={{ width: 190 }}
                  //       value={value}
                  //       onChange={(e) =>
                  //         handleChangeRow("value", e.target.value, item.id)
                  //       }
                  //     />
                  //   ),
                  // },
                  {
                    dataIndex: "length",
                    title: "Length",
                    sorter: false,
                    render: (length, item) => (
                      <Input
                        style={{ width: 190, marginRight: 5 }}
                        value={length}
                        onChange={(e) =>
                          handleChangeRow("length", e.target.value, item.id)
                        }
                      />
                    ),
                  },
                  {
                    dataIndex: "weight",
                    title: "Weight",
                    sorter: false,
                    render: (weight, item) => (
                      <Input
                        style={{ width: 190 }}
                        value={weight}
                        onChange={(e) =>
                          handleChangeRow("weight", e.target.value, item.id)
                        }
                      />
                    ),
                  },
                  {
                    title: "",
                    dataIndex: "_id",
                    align: "right",
                    sorter: false,
                    fixed: "right",
                    width: 100,
                    render: (id) => {
                      return (
                        <Popconfirm
                          title="Sure to delete?"
                          onConfirm={() => handleDelete(id)}
                        >
                          <a style={{ color: "#1b2086" }}>Delete</a>
                        </Popconfirm>
                      );
                    },
                  },
                ]}
                dataSource={partDetails}
                pagination={false}
              />
            </Form>
          </Col>

          <div className="form-group">
            <Button
              type="primary"
              onClick={handleSave}
              loading={loading}
              style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}
            >
              {/* <i className="mdi mdi-content-save mr-5" />  */}
              Submit
            </Button>

            <Button
              htmlType="reset"
              type="primary"
              onClick={handleReset}
              style={{
                backgroundColor: "#1b2086",
                marginLeft: 10,
                borderColor: "#1b2086",
                color: "white",
              }}
            >
              {/* <i className="mdi mdi-content-save mr-5" /> */}
              Reset
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default BillOfMaterialForm;
