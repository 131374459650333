import { Button, Col, Input, message, Row, Avatar, Upload, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./EmployeeForm.css";
import axios from "axios";
import { url } from "../../constant/url";
import profile from "../../assets/profile.jpg";
import { LeftOutlined } from "@ant-design/icons";

const { Option } = Select;

function EmployeeForm({ history, match }) {
  const [data, setData] = useState({
    full_name: "",
    username: "",
    email: "",
    nik: "",
    plan_id: "",
    plan_name: "",
    divisi: "",
    role_id: "",
    role_name: "",
    password: "",
    password_confirmation: "",
    photo: "",
    photo_url: "",
  });

  const [page] = useState(1);
  const [perpage] = useState(10);
  const [sort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    if (match.params.id) {
      getData();
    }
  }, []);

  const handleChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });

    setData({
      ...data,
      [name]: value,
    });
  };

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/user/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const {
          username,
          full_name,
          nik,
          plan_id,
          plan_name,
          divisi,
          password,
          password_confirmation,
          role_id,
          role_name,
          email,
          // photo,
          photo_url,
        } = res.data.data;
        setData({
          ...data,
          username,
          full_name,
          nik,
          plan_id,
          plan_name,
          divisi,
          role_id,
          role_name,
          password,
          password_confirmation,
          email,
          // photo,
          photo_url,
        });
        // console.log(res);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      username: "",
      full_name: "",
      nik: "",
      plan_id: "",
      plan_name: "",
      divisi: "",
      role_id: "",
      role_name: "",
      password: "",
      password_confirmation: "",
      email: "",
    });
  };

  const handleSave = (e) => {
    // console.log(data.email);
    e.preventDefault();
    let fd = new FormData();
    fd.set("username", data.username);
    fd.set("full_name", data.full_name);
    fd.set("email", data.email);
    fd.set("nik", data.nik);
    // if (data.department) {
    //   fd.set("department", data.department);
    // }
    if (data.divisi) {
      fd.set("divisi", data.divisi);
    }
    if (data.password) {
      fd.set("password", data.password);
    }
    if (data.password_confirmation) {
      fd.set("password_confirmation", data.password_confirmation);
    }
    fd.set("role_id", data.role_id);
    fd.set("role_name", data.role_name);
    fd.set("plan_id", data.plan_id);
    fd.set("plan_name", data.plan_name);
    fd.append("photo", data.photo);
    setLoading(true);

    axios
      .post(
        match.params.id ? `${url}/user/${match.params.id}` : `${url}/user`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/employee");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const handleBeforeUpload = (file) => {
    setData({
      ...data,
      photo_url: "",
      photo: file,
    });
    return false;
  };

  const handleRemove = () => {
    setData({
      ...data,
      photo: "",
      photo_url: "",
    });
  };

  const handleChangeSelect = (name, value) => {
    setErrors(null);
    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };
  // console.log(errors);

  const getRole = async (keyword) => {
    await axios
      .get(`${url}/role/list`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setRoles(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getDepartment = async (keyword) => {
    await axios
      .get(`${url}/plan/list`, {
        params: {
          keyword,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setDepartments(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  return (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/employee">
            <LeftOutlined />
            Employee
          </Link>
        </li>
        <li className="active">
          {match.params.id ? "Edit Employee" : "Add Employee"}
        </li>
      </ul>
      <div className="content">
        <form onSubmit={handleSave}>
          <Col span={12}></Col>
          <Col md={4} xs={20}>
            <div style={{ marginRight: 30 }} className="avatar-form">
              <div className="mb-20">
                <Avatar
                  src={
                    data.photo_url
                      ? data.photo_url
                      : data.photo
                      ? URL.createObjectURL(data.photo)
                      : profile
                  }
                  size={100}
                />
              </div>
              <Upload
                onRemove={handleRemove}
                beforeUpload={handleBeforeUpload}
                fileList={[]}
                accept="image/jpg, image/png"
              >
                {data.photo ? (
                  <Button type="danger" onClick={handleRemove}>
                    <i className="mdi mdi-close mr-5" />
                    Delete Foto
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#1b2086",
                      marginBottom: 10,
                    }}
                  >
                    <i className="mdi mdi-upload mr-5" />
                    Upload Foto
                  </Button>
                )}
              </Upload>
            </div>
          </Col>
          <Row gutter={20}>
            <Col md={18} xs={24}>
              <div className="form-group">
                <label className="form-label">
                  NIK <span className="error-text">*</span>
                </label>
                <Input
                  value={data.nik}
                  className={errors && errors.nik ? "is-error" : ""}
                  onChange={(e) => handleChange("nik", e.target.value)}
                />
                {errors && errors.nik && (
                  <span className="error-text">{errors.nik[0]}</span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">
                  Full Name <span className="error-text">*</span>
                </label>
                <Input
                  value={data.full_name}
                  className={errors && errors.full_name ? "is-error" : ""}
                  onChange={(e) => handleChange("full_name", e.target.value)}
                />
                {errors && errors.full_name && (
                  <span className="error-text">{errors.full_name[0]}</span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">Email</label>
                <Input
                  value={data.email}
                  className={errors && errors.email ? "is-error" : ""}
                  onChange={(e) => handleChange("email", e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="form-label">
                  Username <span className="error-text">*</span>
                </label>
                <Input
                  value={data.username}
                  className={errors && errors.username ? "is-error" : ""}
                  onChange={(e) => handleChange("username", e.target.value)}
                />
                {errors && errors.username && (
                  <span className="error-text">{errors.username[0]}</span>
                )}
              </div>

              <div className="form-group">
                Department <span className="error-text">*</span>
                <Select
                  allowClear
                  style={{ width: 693 }}
                  labelInValue
                  onChange={(value) => handleChangeSelect("plan", value)}
                  onFocus={() => getDepartment("")}
                  showSearch
                  onSearch={(value) => getDepartment(value)}
                  filterOption={false}
                  value={{
                    key: data.plan_id,
                    label: data.plan_name,
                  }}
                >
                  {departments &&
                    departments.map((dept) => {
                      return (
                        <Option value={dept._id} key={dept._id}>
                          {dept.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.plan_name && (
                  <span className="error-text">{errors.plan_name[0]}</span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">
                  Division
                  <span className="error-text">*</span>
                </label>
                <Input
                  value={data.divisi}
                  className={errors && errors.divisi ? "is-error" : ""}
                  onChange={(e) => handleChange("divisi", e.target.value)}
                />
                {errors && errors.divisi && (
                  <span className="error-text">{errors.divisi[0]}</span>
                )}
              </div>
              {match.params.id ? (
                <div className="form-group">
                  <label className="form-label">
                    Password
                    {!match.params.id && <span className="error-text">*</span>}
                  </label>
                  <Input
                    className={errors && errors.password ? "is-error" : ""}
                    onChange={(e) => handleChange("password", e.target.value)}
                    type="password"
                  />
                  {errors && errors.password && (
                    <span className="error-text">{errors.password[0]}</span>
                  )}
                </div>
              ) : (
                ""
              )}
              {match.params.id ? (
                <div className="form-group">
                  <label className="form-label">
                    Retype Password
                    {!match.params.id}
                  </label>
                  <Input.Password
                    value={data.password_confirmation}
                    onChange={(e) =>
                      handleChange("password_confirmation", e.target.value)
                    }
                  />
                </div>
              ) : (
                ""
              )}

              <div className="form-group">
                Role <span className="error-text">*</span>
                <Select
                  allowClear
                  style={{ width: 693 }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelect("role", value, data._id)
                  }
                  onFocus={() => getRole("")}
                  // onFocus={() => getRole()}
                  showSearch
                  onSearch={(value) => getRole(value)}
                  filterOption={false}
                  value={{
                    key: data.role_id,
                    label: data.role_name,
                  }}
                >
                  {roles &&
                    roles.map((role) => {
                      return (
                        <Option value={role._id} key={role._id}>
                          {role.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.role_name && (
                  <span className="error-text">{errors.role_name[0]}</span>
                )}
              </div>

              <div className="form-group">
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={loading}
                  style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}
                >
                  {/* <i className="mdi mdi-content-save mr-5" />  */}
                  Submit
                </Button>

                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={handleReset}
                  // loading={loading}
                  style={{
                    backgroundColor: "#1b2086",
                    marginLeft: 10,
                    borderColor: "#1b2086",
                  }}
                >
                  {/* <i className="mdi mdi-content-save mr-5" /> */}
                  Reset
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default EmployeeForm;
