import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Table,
  Card,
  Upload,
} from "antd";
import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { url } from "../../constant/url";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ImportOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import fileDownload from "js-file-download";

function BillOfMaterial(history) {
  const getItems = () => {
    const columns = [
      {
        title: "Child",
        dataIndex: "part_name",
        key: "part_name",
        sorter: false,
        // render: (detail_part) => {
        //   return {
        //     children: (
        //       <div className={detail_part.length <= 5 ? "" : "scroll"}>
        //         {detail_part.map((i, index) => (
        //           <div key={index}>&bull;. {i}</div>
        //         ))}
        //       </div>
        //     ),
        //   };
        // },
      },
      // {
      //   title: "Value",
      //   dataIndex: "value",
      //   key: "value",
      //   sorter: false,
      //   // render: (detail_value) => {
      //   //   return {
      //   //     children: (
      //   //       <div className={detail_value.length <= 5 ? "" : "scroll"}>
      //   //         {detail_value.map((i, index) => (
      //   //           <div key={index}>&bull;. {i}</div>
      //   //         ))}
      //   //       </div>
      //   //     ),
      //   //   };
      //   // },
      // },
      {
        title: "Length",
        dataIndex: "length",
        key: "length",
        sorter: false,
      },

      {
        title: "Weight",
        dataIndex: "weight",
        key: "weight",
        sorter: false,
      },
    ];

    if (!localStorage.getItem("token")) {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <Table
          size="large"
          columns={columns}
          rowKey={(data, index) => index}
          loading={loadingItems}
          dataSource={manifestItems}
          pagination={{
            pageSize: perPageDetail,
            current: pageDetail,
            total: manifestItemsTotal,
            showSizeChanger: true,
            // showTotal: showTotal,
            style: {
              marginTop: "2rem",
              marginBottom: 0,
            },
          }}
          onChange={handleChangeItemsTable}
          bordered
        />
      </div>
    );
  };
  const columns = [
    {
      title: "Parent",
      dataIndex: "part_number",
      key: "part_number",
      sorter: true,
    },
    {
      title: "",
      dataIndex: "_id",
      key: "id",
      sorter: false,
      fixed: "right",
      width: 50,
      render: (id) => {
        return (
          <>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Link to={`/bill-of-material/edit/${id}`}>
                      {/* <i className="mdi mdi-pencil mr-5" /> Edit */}
                      <EditOutlined /> Edit
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <span onClick={() => handleDelete(id)}>
                      {/* <i className="mdi mdi-delete mr-5" /> */}
                      <DeleteOutlined />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <i className="mdi mdi-dots-vertical" />
              </span>
            </Dropdown>
          </>
        );
      },
    },
  ];

  const [total, setTotal] = useState([]);
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });
  const [loading, setLoading] = useState(false);
  const [rowItems, setRowItems] = useState([]);
  const [loadingItems, setLoadingItems] = useState(false);
  const [keywordItems, setKeywordItems] = useState("");
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [manifestItems, setManifestItems] = useState([]);
  const [manifestItemsTotal, setManifestItemsTotal] = useState(0);
  const [error, setError] = useState(null);
  const [boms, setBom] = useState([]);

  const [data, setData] = useState({
    file: null,
  });

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  useEffect(() => {
    getData();
  }, [keyword, page, perpage, sort]);

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/bill-of-material`, {
        params: {
          keyword,
          columns: ["part_number", "part_detail", "child", "value"],
          perpage: perpage,
          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setBom(res.data);
        setTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeItems = (data) => {
    const index = data[data.length - 1];
    setRowItems([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getManifestItems(data2[0]);
    }
  };

  const getManifestItems = (id = selectedId) => {
    setLoadingItems(true);
    axios
      .get(`${url}/bill-of-material/details/${id}`, {
        params: {
          keyword: keywordItems,
          columns: ["part_number", "part_detail", "child", "value"],
          perpage: perPageDetail,
          page: pageDetail,
        },

        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setManifestItems(res.data.data);
        setManifestItemsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet started");
        }
      })
      .finally(() => {
        setLoadingItems(false);
      });
  };

  const handleChangeItemsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setPerPageDetail(pagination.pageSize);
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure ?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    axios
      .delete(`${url}/bill-of-material/${id}`, {
        params: {
          keyword,
          columns: ["part_number", "part_detail", "child", "value"],
          perpage: perpage,
          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPerpage(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const downloadTemplate = () => {
    axios
      .get(`${url}/bill-of-material/download`, {
        params: {
          keyword,
          columns: ["part_number", "part_detail", "child", "value"],
          perpage: perpage,
          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, "export_bill_of_material.xlsx");
      });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    axios
      .post(`${url}/bill-of-material/import`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  return localStorage.getItem("token") ? (
    <div>
      <ul className="breadcumb">
        <li className="active">Bill Of Material</li>
      </ul>
      <div className="content">
        <Row justify="space-between">
          <Col>
            <Link to="/bill-of-material/create">
              <Button
                type="primary"
                style={{ backgroundColor: "#1b2086", borderColor: "#1b2086" }}
              >
                <PlusOutlined />
                Add
              </Button>
            </Link>
            <Modal
              title="Import Bill Of Material"
              visible={isModalVisible}
              footer={null}
              onCancel={closeModal}
              onCancel={handleCancel}
            >
              <Card className="body-data">
                <div name="control-hooks">
                  <div name="file" label="File">
                    <Fragment>
                      <Upload
                        onRemove={handleRemove}
                        beforeUpload={handleBeforeUpload}
                        fileList={[]}
                      >
                        <Button style={{ marginBottom: 10 }}>
                          Choose File <ExportOutlined />
                        </Button>
                      </Upload>
                      {data.file && (
                        <React.Fragment
                          children={[
                            <Button onClick={handleRemove} type="danger" ghost>
                              <DeleteOutlined />
                            </Button>,
                          ]}
                        >
                          {data.file.name ? data.file.name : data.file}
                        </React.Fragment>
                      )}
                    </Fragment>
                  </div>
                  <div>
                    <Button
                      loading={loading}
                      onClick={onSaveData}
                      type="primary"
                      htmlType="submit"
                      className="mr-button"
                      style={{
                        marginRight: 10,
                        backgroundColor: "#1b2086",
                        borderColor: "#1b2086",
                      }}
                    >
                      Upload
                    </Button>
                    <Button htmlType="reset" onClick={handleRemove}>
                      Reset
                    </Button>
                  </div>
                </div>
              </Card>
            </Modal>
            <Button
              onClick={showModal}
              type="primary"
              style={{
                backgroundColor: "#1b2086",
                borderColor: "#1b2086",
                marginLeft: 10,
              }}
            >
              <ImportOutlined />
              Import
            </Button>
            <Button
              onClick={downloadTemplate}
              type="primary"
              style={{
                backgroundColor: "#1b2086",
                borderColor: "#1b2086",
                marginLeft: 10,
              }}
            >
              <ExportOutlined />
              Export
            </Button>
          </Col>
          <Col>
            <Input.Search
              allowClear="true"
              onSearch={(value) => setKeyword(value)}
              placeholder="Search..."
            />
          </Col>
        </Row>
        <Row className="mt-40">
          <Col xs={24}>
            <Table
              expandable={{
                expandedRowRender: getItems,
                onExpandedRowsChange: (data) => handleChangeItems(data),
                expandedRowKeys: rowItems,
              }}
              columns={columns}
              dataSource={boms.data}
              pagination={{ total, showSizeChanger: true }}
              loading={loading}
              rowKey={(data) => data._id}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </div>
    </div>
  ) : (
    <Redirect to="/login" />
  );
}

export default BillOfMaterial;
