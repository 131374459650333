import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Table,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { url } from "../../constant/url";
import "./User.css";
import profile from "../../assets/profile.jpg";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";

function User({ history }) {
  const columns = [
    // {
    //   title: "Photo",
    //   dataIndex: "photo_url",
    //   key: "photo_url",
    //   sorter: false,
    //   width: 100,
    //   render: (photo_url) =>
    //     photo_url ? <Avatar src={photo_url} /> : <Avatar src={profile} />,
    // },
    {
      title: "Full Name",
      dataIndex: "full_name",
      key: "full_name",
      sorter: true,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      sorter: false,
    },

    {
      title: "",
      dataIndex: "_id",
      key: "id",
      sorter: false,
      fixed: "right",
      width: 50,
      render: (id) => {
        return (
          <>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Link to={`/user/edit/${id}`}>
                      {/* <i className="mdi mdi-pencil mr-5" /> Edit */}
                      <EditOutlined /> Edit
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <span onClick={() => handleDelete(id)}>
                      {/* <i className="mdi mdi-delete mr-5" /> */}
                      <DeleteOutlined />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <i className="mdi mdi-dots-vertical" />
              </span>
            </Dropdown>
          </>
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const [page, setPage] = useState(1);

  const [perpage, setPerpage] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    getData();
  }, [keyword, page, perpage, sort]);

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/user`, {
        // params: {
        //   keyword,
        //   limit: perpage,
        //   page,
        //   sortBy: `${sort.sort}:${sort.order}`,
        // },
        params: {
          keyword,
          columns: ["fullname", "username"],
          perpage: perpage,
          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (error.response) {
          message.error(error.response.data.message);
          history.push("/login");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure ?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    axios
      .delete(`${url}/user/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPerpage(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  return (
    <div>
      <ul className="breadcumb">
        <li className="active">User</li>
      </ul>
      <div className="content">
        <Row justify="space-between">
          <Col>
            <Link to="/user/create">
              <Button
                type="primary"
                style={{ backgroundColor: "#069964", borderColor: "#069964" }}
              >
                {/* <i className="mdi mdi-plus mr-5" /> */}
                <PlusOutlined />
                Add
              </Button>
            </Link>
          </Col>
          <Col>
            <Input.Search
              allowClear="true"
              onSearch={(value) => setKeyword(value)}
              placeholder="Search..."
            />
          </Col>
        </Row>
        <Row className="mt-40">
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={data}
              pagination={{ total, showSizeChanger: true }}
              loading={loading}
              rowKey={(data) => data._id}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default User;
