import React from "react";
import NumberFormat from "react-number-format";

function AntNumberFormat(props) {
  return (
    <NumberFormat
      disabled={props.disabled ? props.disabled : false}
      displayType={props.type === "text" ? "text" : "input"}
      //prefix="IDR"
      value={props.value}
      decimalSeparator="."
      thousandSeparator=","
      //className="ant-input text-right"
      className={`${props.type === "input" ? "ant-input" : ""} text-right`}
      //onValueChange={(e) => props.onChange(e.floatValue)}
      onValueChange={(e) =>
        props.onChange ? props.onChange(e.floatValue) : true
      }
    />
  );
}

export default AntNumberFormat;
